import React, { useCallback, useMemo, useState } from 'react';
import { microwave, OrderingValues } from '@round/api';
import useUrlState from '../../../../../../Hooks/useUrlState';
import { OrderByParam } from '../../../../../../Hooks/useReactTableSortToOrderBy';
import {
    convertOrderByToOrdering,
    convertOrderingToOrderBy,
} from '../../../../../../ui/PaginationTable/PaginationTable.helpers';
import useTiktokPostInvites, { TiktokPostInviteData } from './useTiktokPostInvites';
import styles from './Contacted.module.css';
import { Modal, Skeleton } from '@round/ui-kit';
import EmailHistory from './EmailHistory/EmailHistory';
import PostedTable from './PostedTable/PostedTable';

type Props = {
    planId: number;
};

type UrlState = {
    page: number;
    page_size: number;
} & Partial<{ ordering: OrderingValues<microwave.GetTiktokPostInvitesSortingValues> }>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    ordering: '-post_create_time',
};

const Posted = ({ planId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    const [isEmailHistoryModalOpen, setIsEmailHistoryModalOpen] = useState(false);
    const [emailHistoryPostInvite, setEmailHistoryPostInvite] = useState<TiktokPostInviteData | null>(null);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const ordering = useMemo(
        () =>
            urlState.ordering && microwave.isGetTiktokPostInvitesSortingValues(urlState.ordering)
                ? urlState.ordering
                : undefined,
        [urlState.ordering]
    );

    const setOrdering = useCallback(
        (orderBy: OrderByParam<TiktokPostInviteData>) => {
            setUrlState({ ordering: convertOrderByToOrdering(orderBy) as microwave.GetTiktokPostInvitesSortingValues });
        },
        [setUrlState]
    );

    const { creators, loading, count, errorLoading, isInitialized } = useTiktokPostInvites({
        creator_plan: planId,
        page: page,
        page_size: pageSize,
        ordering,
        is_posted: true,
    });

    const [rowsDisplayed, setRowsDisplayed] = useState(0);
    const rowsDisplayedText = `displaying ${rowsDisplayed} out of ${count}`;
    const isTableLoading = loading || (!errorLoading && !isInitialized);

    return (
        <div className={styles.container}>
            <p className={styles.rowsDisplayedText}>{isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}</p>

            <PostedTable
                isLoading={isTableLoading}
                data={creators}
                count={count}
                page={page}
                setPage={(page) => setUrlState({ page })}
                orderBy={convertOrderingToOrderBy(ordering)}
                disableSortBy={false}
                onOrderByChange={setOrdering}
                manualSortBy
                pageSize={pageSize}
                setPageSize={(pageSize) => setUrlState({ page_size: pageSize ?? initialUrlState.page_size })}
                noDataLabel={errorLoading ? "Sorry, we're experiencing technical issues" : 'No data found'}
                onPaginationChange={(state) => setRowsDisplayed(state.pageRows.length)}
                onEmailHistoryClicked={(row) => {
                    setEmailHistoryPostInvite(row);
                    setIsEmailHistoryModalOpen(true);
                }}
            />

            <Modal
                closeOnOverlayClick
                className={styles.emailHistoryModal}
                isOpen={Boolean(isEmailHistoryModalOpen && emailHistoryPostInvite)}
                onClose={() => {
                    setIsEmailHistoryModalOpen(false);
                    setEmailHistoryPostInvite(null);
                }}
            >
                {emailHistoryPostInvite && <EmailHistory contactedCreator={emailHistoryPostInvite} />}
            </Modal>
        </div>
    );
};

export default Posted;
