import { CellContext, HeaderContext, RowData } from '@tanstack/react-table';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import styles from './SelectCell.module.css';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';

type BaseMeta = {
    isLoading: boolean | undefined;
};

export const SelectCell = <TRow extends RowData, TMeta extends BaseMeta>({
    table,
    row,
}: CellContext<TRow, boolean>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <div className={styles.container}>
            <Checkbox
                ariaLabel="Select row"
                value={row.getIsSelected()}
                onChange={() => {
                    row.toggleSelected();
                }}
            />
        </div>
    );
};

export const SelectHeader = <TRow extends RowData, TMeta extends BaseMeta>({ table }: HeaderContext<TRow, boolean>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <div className={styles.container}>
            {table.getRowModel().rows.length > 0 && (
                <Checkbox
                    ariaLabel="Select all"
                    value={table.getIsAllPageRowsSelected()}
                    indeterminateValue={table.getIsSomePageRowsSelected()}
                    onChange={() => table.toggleAllPageRowsSelected()}
                />
            )}
        </div>
    );
};
