import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { HeaderContext } from '@tanstack/react-table';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { isNumber } from 'utility/utility';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = HeaderContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostStatsViewsFooterCell = <T extends Row>({ table }: Context<T>) => {
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    const rows = table.getRowModel().rows;

    if (!rows.some((row) => isNumber(row.original.view_count))) {
        return '-';
    }

    const totalViews = rows.reduce((acc, row) => {
        const views = row.original.view_count ?? 0;
        return acc + views;
    }, 0);

    return formatPostStatsCellValue(totalViews);
};

export default PostStatsViewsFooterCell;
