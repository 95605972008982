import { PropsWithChildren } from 'react';
import useNonNullContext from '../../../../hooks/useNonNullContext';
import { ContextState, TabsContext } from '../../Tabs';
import styles from './TabNavItem.module.css';
import cn from 'classnames';
import Button from '../../../Buttons/Button/Button';

export type TabNavItemProps = PropsWithChildren<{
    name: ContextState['activeTab'];
    className?: string;
    activeClassName?: string;
}>;

const TabNavItem = ({ name, children, className, activeClassName = '' }: TabNavItemProps) => {
    const { activeTab, onChange } = useNonNullContext(TabsContext);

    return (
        <Button
            className={cn(styles.button, className, {
                [styles.active]: activeTab === name,
                [activeClassName]: activeTab === name,
            })}
            onClick={() => onChange(name)}
            name={name}
        >
            {children}
        </Button>
    );
};

export default TabNavItem;
