export type PaymentMethod = 'VENMO' | 'PAYPAL' | 'BANK_TRANSFER';
export type CampaignStatus = 'Completed' | 'Live' | 'Planned';
export type PaymentStatus = 'UNPAID' | 'IN_PROGRESS' | 'PAID' | 'FAILED';
export type Platform = 'tiktok' | 'instagram' | 'youtube';

export const isPlatform = (platform: any): platform is Platform => {
    const platforms: Platform[] = ['tiktok', 'instagram', 'youtube'];
    return typeof platform === 'string' && platforms.includes(platform as Platform);
};

export type PaymentRequestFormState = 'FORM_OPEN' | 'FORM_SUBMITTED' | 'FORM_CLOSED';
