import React, { useMemo } from 'react';
import styles from './ApproveTiktokPostsTable.module.css';
import PaginationTable, { PaginationTableProps } from '../../../../../../ui/PaginationTable/PaginationTable';
import { TiktokAudio, microwave } from '@round/api';
import { Column } from 'react-table';
import { Skeleton } from '@round/ui-kit';
import { InfluencerPlan } from '../../../../../../App.types';
import TiktokAudioBadge from '../../../../../TikTok/components/TiktokAudioBadge/TiktokAudioBadge';
import { AudioPlayerProvider } from '../../../../../AudioPlayer/AudioPlayerContext';
import cn from 'classnames';
import InfluencerPlanBadge from '../../../../InfluencerPlan/components/InfluencerPlanBadge/InfluencerPlanBadge';
import TruncatedTextCell from '../../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { buildInfluencerPlanUrl } from '../../../../InfluencerPlan/InfluencerPlan.helpers';
import { buildTiktokUserUrl, formatDate } from '../../../../../../helpers';
import moment from 'moment';

export type ApproveTiktokPostsTableRow = microwave.TiktokMicrowaveInfluencerPost & {
    influencer_plan: InfluencerPlan | undefined;
    audio: TiktokAudio | undefined;
    audioImageUrl: string | undefined;
    influencer: microwave.MicrowaveInfluencer | undefined;
};

type Props = Omit<PaginationTableProps<ApproveTiktokPostsTableRow>, 'columns' | 'loading' | 'data'> & {
    rows: ApproveTiktokPostsTableRow[];
    onClickUpdatePaymentStatus: (row: ApproveTiktokPostsTableRow, status: microwave.PaymentRequestStatus) => void;
    isLoading: boolean;
};

const ApproveTiktokPostsTable = ({ rows, onClickUpdatePaymentStatus, ...props }: Props) => {
    const columns: Column<ApproveTiktokPostsTableRow>[] = useMemo(
        () => [
            {
                Header: 'Campaign',
                accessor: 'influencer_plan',
                Cell: ({ value: plan }) => {
                    const microsPlanUrl = plan?.release.id
                        ? `${buildInfluencerPlanUrl(plan?.release.id)}#micro`
                        : undefined;
                    if (!props.isLoading && !plan) {
                        return <>-</>;
                    }

                    return (
                        <InfluencerPlanBadge
                            className={styles.campaign}
                            planInfo={{
                                releaseId: plan?.release.id,
                                releaseName: plan?.release.name,
                                artistName: plan?.release.brand.name,
                                imageUrl: plan?.release.brand.picture,
                                planUrl: microsPlanUrl,
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Audio',
                accessor: 'audio',
                Cell: ({ value, row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return (
                        <TiktokAudioBadge
                            className={styles.audio}
                            audio={{
                                tiktokId: value.tiktok_id ?? '',
                                title: value.title ?? '',
                                authorName: value.author_name,
                                isOriginal: value.is_original,
                                imageUrl: original.audioImageUrl,
                                audioPlayUrl: '',
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Brief',
                accessor: 'brief',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <TruncatedTextCell className={styles.brief}>{value}</TruncatedTextCell>;
                },
            },
            {
                Header: 'Post',
                accessor: 'post_url',
                Cell: ({ value }) =>
                    props.isLoading ? (
                        <Skeleton />
                    ) : (
                        <a href={value} target="_blank" rel="noreferrer">
                            Post link ↗
                        </a>
                    ),
            },
            {
                Header: 'Influencer',
                accessor: 'influencer',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (!value?.tiktok_user_unique_id) {
                        return <>-</>;
                    }

                    return (
                        <a
                            href={buildTiktokUserUrl(value.tiktok_user_unique_id)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            @{value.tiktok_user_unique_id}
                        </a>
                    );
                },
            },
            {
                Header: 'Influencer Email',
                accessor: 'influencer_email_address',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Post Date',
                accessor: 'posted_at',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <div className={styles.date}>{value ? formatDate(value) : '-'}</div>;
                },
            },
            {
                Header: 'Post Deadline',
                accessor: 'post_deadline',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDate(value)}</>;
                },
            },
            {
                Header: 'Payment Request Date',
                accessor: 'payment_requested_at',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <div className={styles.date}>{value ? formatDate(value) : '-'}</div>;
                },
            },
            {
                Header: 'Payment Due Date',
                accessor: 'payment_deadline',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDate(value)}</>;
                },
            },
            {
                Header: 'Approved By',
                accessor: 'approved_by',
                Cell: ({ value }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Action',
                accessor: 'payment_request_status',
                Cell: ({ value, row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.actions}>
                            <button
                                className={cn(styles.approve, { [styles.disabled]: value === 'APPROVED' })}
                                disabled={value === 'APPROVED'}
                                onClick={() => onClickUpdatePaymentStatus(original, 'APPROVED')}
                            >
                                Approve
                            </button>
                            <button
                                className={cn(styles.reject, { [styles.disabled]: value === 'REJECTED' })}
                                disabled={value === 'REJECTED'}
                                onClick={() => onClickUpdatePaymentStatus(original, 'REJECTED')}
                            >
                                Reject
                            </button>
                        </div>
                    );
                },
            },
        ],
        [props.isLoading, onClickUpdatePaymentStatus]
    );

    return (
        <AudioPlayerProvider>
            <PaginationTable
                data={rows}
                columns={columns}
                loading={props.isLoading}
                tableClassName={styles.table}
                {...props}
                renderRow={(props) => {
                    const isPastDeadline = moment(props.row.original.posted_at).isAfter(
                        props.row.original.post_deadline,
                        'days'
                    );

                    const campaignRanges: Record<number, [number, number]> = props.rows.reduce((acc, row) => {
                        if (!acc[row.original.plan]) {
                            acc[row.original.plan] = [row.index, row.index];
                            return acc;
                        }

                        const [start, end] = acc[row.original.plan];
                        if (row.index > end) {
                            acc[row.original.plan] = [start, row.index];
                            return acc;
                        }

                        return acc;
                    }, {} as Record<number, [number, number]>);

                    return (
                        <tr {...props.row.getRowProps()}>
                            {props.row.cells.map((cell, index) => {
                                const columnsToMerge = ['influencer_plan', 'audio', 'brief'];
                                const ranges = campaignRanges[props.row.original.plan];
                                if (
                                    columnsToMerge.includes(cell.column.id) &&
                                    Array.isArray(ranges) &&
                                    props.row.index > ranges[0]
                                ) {
                                    return null;
                                }

                                const rowSpan =
                                    columnsToMerge.includes(cell.column.id) &&
                                    Array.isArray(ranges) &&
                                    props.row.index === ranges[0]
                                        ? ranges[1] - ranges[0] + 1
                                        : 1;
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        rowSpan={rowSpan}
                                        className={cn({
                                            [styles.postedAfterDeadline]:
                                                isPastDeadline &&
                                                (!columnsToMerge.includes(cell.column.id) || rowSpan === 1),
                                        })}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                }}
            />
        </AudioPlayerProvider>
    );
};

export default ApproveTiktokPostsTable;
