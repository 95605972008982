import { makePaymentRequestsListDataHook } from '../../hooks/dataHooks/usePaymentRequestsDataHook';
import { microwave } from '@round/api';
import { ApprovalsContext } from '../context/ApprovalsContext';
import makeCreatorsByIdDataHook from '../../hooks/dataHooks/useCreatorsByIdDataHook';
const usePaymentRequests = makePaymentRequestsListDataHook(
    ApprovalsContext,
    ([state]) => state.instagram.paymentRequests
);

const useInfluencers = makeCreatorsByIdDataHook(ApprovalsContext, ([state]) => state.influencers);

export default function useApproveInstagramPosts(params: microwave.GetPaymentRequestsParams) {
    const { data, ...restOfState } = usePaymentRequests({ params });

    const influencerIds = data?.results.map((result) => result.influencer_id) || [];
    const { data: influencersData } = useInfluencers({
        ids: influencerIds,
        isDisabled: (state) => {
            const idsInState = Object.keys(state).map(Number);

            return influencerIds.every((id) => idsInState.includes(id) && state[id]?.status === 'success');
        },
    });

    return {
        data,
        influencersData,
        ...restOfState,
    };
}
