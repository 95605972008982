import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import styles from './EmailPreviewModal.module.css';
import useEmailTemplatesSelect from '../../hooks/useEmailTemplatesSelect';
import { notificationTypeLabels } from '../../MicroCreators/helpers';
import Select from 'ui-new/Select/Select';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const NOTIFICATION_TYPE = 'SONG_PROMO_CHASE';

const ChaseEmailPreviewModal = ({ isOpen, onClose }: Props) => {
    const {
        selectedTemplate,
        setSelectedTemplate,
        templateOptions,
        status: templatesStatus,
        error: templatesError,
    } = useEmailTemplatesSelect(NOTIFICATION_TYPE);

    return (
        <FullScreenModal isOpen={isOpen} onClose={onClose}>
            <FullScreenModal.Header>Email Preview</FullScreenModal.Header>
            <FullScreenModal.Content className={styles.container}>
                <div className={styles.selectContainer}>
                    <label className={styles.selectLabel}>{notificationTypeLabels[NOTIFICATION_TYPE]} Templates</label>
                    <Select
                        isLoading={templatesStatus === 'not-initialized'}
                        options={templateOptions}
                        value={selectedTemplate}
                        onChange={(opt) => {
                            if (opt?.value === selectedTemplate?.value) {
                                return;
                            }

                            setSelectedTemplate(opt);
                        }}
                        noOptionsMessage={() => templatesError || 'No options'}
                    />
                </div>
            </FullScreenModal.Content>
        </FullScreenModal>
    );
};

export default ChaseEmailPreviewModal;
