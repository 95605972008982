import { useCallback, useMemo, useState } from 'react';
import {
    TiktokUserImage,
    getTiktokUserImages,
    microwave,
    InstagramUserImage,
    getInstagramUserImages,
    youtube,
} from '@round/api';
import debounce from 'lodash/debounce';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../helpers';
import { NamedProps, OptionsType, ValueType } from 'react-select';
import { isNumber } from 'lodash';

/**
 * @deprecated
 */
export type MicrowaveInfluencerOption = {
    value: number;
    label: string;
    tiktokUniqueId: string;
    tiktokNickname?: string;
    instagramUniqueId?: string | null;
    imageUrl?: string;
    youtubeChannelTitle: string | null;
};

type Return = Pick<
    NamedProps<MicrowaveInfluencerOption, false>,
    | 'filterOption'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'onMenuScrollToBottom'
    | 'inputValue'
    | 'onInputChange'
    | 'isLoading'
    | 'value'
    | 'onChange'
> & {
    options: OptionsType<MicrowaveInfluencerOption>;
};

const defaultPageSize = 25;

const mapInfluencerToOption = (
    inf: microwave.MicrowaveInfluencer,
    imageUrl: string | undefined
): MicrowaveInfluencerOption => ({
    value: inf.id,
    label: inf.email,
    tiktokNickname: inf.tiktok_user_nickname,
    tiktokUniqueId: inf.tiktok_user_unique_id,
    instagramUniqueId: inf.instagram_user_username,
    imageUrl,
    youtubeChannelTitle: inf.youtube_channel_title,
});

/**
 * @deprecated
 */
export default function useMicrowaveInfluencersSelect(
    initialInfluencerId: number | undefined,
    params: {
        initOptionsOnMenuOpen?: boolean;
    } = {}
): Return {
    const [value, setValue] = useState<ValueType<MicrowaveInfluencerOption, false>>(null);

    useAbortableEffect(
        (signal) => {
            async function getInitialInfluencer(id: number) {
                try {
                    const response = await microwave.getInfluencer(id, { signal });
                    if (response.status === 404) {
                        showNotification('Could not find initial influencer', 'error');
                        return;
                    }

                    const image = await getTiktokUserImages([id], { signal });
                    const imageUrl = image[0]?.avatar_thumb.cached_url ?? image[0]?.avatar_thumb.original_url;

                    setValue(mapInfluencerToOption(response.data, imageUrl));
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not fetch initial influencer', 'error');
                }
            }

            if (typeof initialInfluencerId === 'number' && value?.value !== initialInfluencerId) {
                getInitialInfluencer(initialInfluencerId);
            }
        },
        [initialInfluencerId, value?.value]
    );

    const [influencers, setInfluencers] = useState<microwave.MicrowaveInfluencer[]>([]);
    const [tiktokImages, setTiktokImages] = useState<TiktokUserImage[]>([]);
    const [instagramImages, setInstagramImages] = useState<InstagramUserImage[]>([]);
    const [youtubeChannels, setYoutubeChannels] = useState<youtube.Channel[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const fetchInfluencers = useCallback(
        async (params: microwave.GetMicrowaveInfluencersParams, requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const {
                    data: { results: influencers, next },
                } = await microwave.getMicrowaveInfluencers(params, requestInit);
                setInfluencers((prev) => prev.concat(influencers));
                setHasNextPage(Boolean(next));

                const tiktokImagesToFetch = influencers.map((inf) => inf.tiktok_user_id).filter(isNumber);
                const instagramImagesToFetch = influencers.map((inf) => inf.instagram_user_id).filter(isNumber);
                const youtubeChannelIds = influencers.map((inf) => inf.youtube_channel_id).filter(isNumber);

                await Promise.allSettled([
                    tiktokImagesToFetch.length
                        ? getTiktokUserImages(tiktokImagesToFetch, requestInit).then((images) =>
                              setTiktokImages((prev) => prev.concat(images))
                          )
                        : Promise.resolve(),
                    instagramImagesToFetch.length
                        ? getInstagramUserImages(instagramImagesToFetch, requestInit).then((res) =>
                              setInstagramImages((prev) => prev.concat(res.data))
                          )
                        : Promise.resolve(),
                    youtubeChannelIds.length
                        ? youtube
                              .getChannels({ id: youtubeChannelIds.join(','), page_size: youtubeChannelIds.length })
                              .then((res) =>
                                  res.status === 200
                                      ? setYoutubeChannels((prev) => prev.concat(res.data.results))
                                      : Promise.resolve()
                              )
                        : Promise.resolve(),
                ]);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification('Could not fetch influencers', 'error');
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        []
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && (!params.initOptionsOnMenuOpen || isMenuOpen)) {
                fetchInfluencers({ page: 1, page_size: defaultPageSize, search }, { signal }).then(() =>
                    setIsInitialized(true)
                );
            }
        },
        [fetchInfluencers, isInitialized, isMenuOpen, search, params.initOptionsOnMenuOpen]
    );

    const debouncedFetchOptions = useMemo(() => debounce(fetchInfluencers, 700), [fetchInfluencers]);

    const loadNextPage = useCallback(async () => {
        fetchInfluencers({ page: page + 1, page_size: defaultPageSize, search }).then(() =>
            setPage((page) => page + 1)
        );
    }, [fetchInfluencers, page, search]);

    const reset = useCallback(() => {
        setInfluencers([]);
        setTiktokImages([]);
        setInstagramImages([]);
        setYoutubeChannels([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
    }, []);

    const options: MicrowaveInfluencerOption[] = useMemo(
        () =>
            influencers.map((inf) => {
                const tiktokImage = tiktokImages.find((image) => image.user_id === inf.tiktok_user_id);
                const tiktokImageUrl = tiktokImage?.avatar_thumb.cached_url || tiktokImage?.avatar_thumb.original_url;
                const instagramImage = instagramImages.find((image) => image.user_id === inf.instagram_user_id);
                const instagramImageUrl =
                    instagramImage?.avatar_thumb.cached_url || instagramImage?.avatar_thumb.original_url;
                const youtubeChannelImageUrl =
                    youtubeChannels.find((channel) => channel.id === inf.youtube_channel_id)?.thumbnail || '';
                return mapInfluencerToOption(inf, tiktokImageUrl || instagramImageUrl || youtubeChannelImageUrl);
            }),
        [influencers, tiktokImages, instagramImages, youtubeChannels]
    );

    return {
        value,
        onChange: (value) => {
            setValue(value);
        },
        options,
        filterOption: null,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            debouncedFetchOptions({ page: 1, page_size: defaultPageSize, search: value });
            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            loadNextPage();
        },
        onMenuOpen: () => setIsMenuOpen(true),
        onMenuClose: () => {
            setIsMenuOpen(false);
        },
        isLoading: isLoading || isSearchLoading,
    };
}
