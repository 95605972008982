import { microwave } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<{
    paymentRequests: microwave.PaymentRequest[];
    totals?: microwave.PaymentRequestTotals;
    count: number;
}>;

const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

export function useMicrowavePaymentRequests() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: microwave.GetPaymentRequestsParams, requestInit?: RequestInit) => {
        try {
            setState({ status: 'loading', data: null, error: null });
            const response = await microwave.getPaymentRequests(params, requestInit);

            if (response.status === 404) {
                setState({ status: 'error', error: response.data.detail, data: null });
                return response;
            }

            setState({
                status: 'success',
                data: {
                    paymentRequests: response.data.results,
                    totals: response.data.totals,
                    count: response.data.count,
                },
                error: null,
            });

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState({
                status: 'error',
                error: e instanceof Error ? e.message : 'Could not load payment requests',
                data: null,
            });
            throw e;
        }
    }, []);

    const update = useCallback(async (id: number, data: microwave.PatchPaymentRequestBody) => {
        const response = await microwave.patchPaymentRequest(id, data);

        if (response.status === 200) {
            setState((prevState) => {
                if (!prevState.data) {
                    return prevState;
                }

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        paymentRequests: prevState.data.paymentRequests.map((request) =>
                            request.id === id ? { ...request, ...response.data } : request
                        ),
                    },
                };
            });
        }
        return response;
    }, []);

    const makePaypalPayment = useCallback(async (id: number) => {
        const response = await microwave.makePaypalPayment(id);

        if (response.status === 204) {
            setState((prevState) => {
                if (!prevState.data) {
                    return prevState;
                }

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        paymentRequests: prevState.data.paymentRequests.filter((request) => request.id !== id),
                    },
                };
            });
        }
        return response;
    }, []);

    const markAsPaidManually = useCallback(async (id: number) => {
        const response = await microwave.makeBankTransfer(id);

        if (response.status === 204) {
            setState((prevState) => {
                if (!prevState.data) {
                    return prevState;
                }

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        paymentRequests: prevState.data.paymentRequests.filter((request) => request.id !== id),
                    },
                };
            });
        }

        return response;
    }, []);

    const makeVenmoPayment = useCallback(async (id: number) => {
        const response = await microwave.makeVenmoPayment(id);

        if (response.status === 204) {
            setState((prevState) => {
                if (!prevState.data) {
                    return prevState;
                }

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        paymentRequests: prevState.data.paymentRequests.filter((request) => request.id !== id),
                    },
                };
            });
        }
        return response;
    }, []);

    const reset = () => {
        setState(initialState);
    };

    return {
        ...state,
        fetchData,
        update,
        reset,
        makePaypalPayment,
        markAsPaidManually,
        makeVenmoPayment,
    };
}
