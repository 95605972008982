import { microwave } from '@round/api';
import Modal from 'ui-new/Modals/Modal/Modal';
import Button from 'ui/Button/Button';
import { Form, FormikProvider, useFormik } from 'formik';
import FormField from 'ui-new/formik/FormField';
import TextAreaField from 'ui-new/formik/TextAreaField';
import styles from './EmailConfigModal.module.css';
import { useState } from 'react';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { showNotification } from 'helpers';
import { Banner } from '@round/ui-kit';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    campaign: microwave.Campaign | null;
    updateCampaign: typeof microwave.patchCampaign;
};

type FormValues = {
    brief: string;
    deadline: string;
};

const EmailConfigModal = ({ isOpen, onClose, campaign, updateCampaign }: Props) => {
    const [nonFieldError, setNonFieldError] = useState<string | null>(null);

    const formik = useFormik<FormValues>({
        initialValues: {
            brief: campaign?.brief ?? '',
            deadline: campaign?.payment_request_deadline_in_days.toString() ?? '2',
        },
        onSubmit: async (values, { setErrors, resetForm }) => {
            if (!campaign) {
                return;
            }

            try {
                const { status, data } = await updateCampaign(campaign.id, {
                    brief: values.brief,
                    payment_request_deadline_in_days: Number(values.deadline),
                });

                if (status === 404) {
                    setNonFieldError(data.detail);
                    return;
                }

                if (status === 400 && Array.isArray(data)) {
                    setNonFieldError(data.toString());
                    return;
                }

                if (status === 400 && !Array.isArray(data)) {
                    setErrors(
                        mapApiErrorsToFormikErrors({
                            brief: data.brief,
                            deadline: data.payment_request_deadline_in_days,
                        })
                    );
                    return;
                }

                resetForm();
                setNonFieldError(null);
                onClose();
                showNotification('Email config updated', 'info');
            } catch (e) {
                setNonFieldError('Could not update email config');
            }
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
        setNonFieldError(null);
    };

    return (
        <Modal className={styles.modal} closeOnEscape closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <Modal.Header>Email configuration</Modal.Header>
            <FormikProvider value={formik}>
                <Form className={styles.form}>
                    <FormField
                        label="Deadline after (days)"
                        name="deadline"
                        type="number"
                        placeholder="Enter number of days"
                    />
                    <TextAreaField
                        label="Brief"
                        name="brief"
                        type="textarea"
                        placeholder="Enter brief"
                        inputClassName={styles.briefField}
                    />
                </Form>
            </FormikProvider>

            {nonFieldError && (
                <Banner appearance="danger" className={styles.errorBanner}>
                    {nonFieldError}
                </Banner>
            )}

            <Modal.Actions>
                <Button appearance="ghost" onClick={handleClose}>
                    Close
                </Button>

                <Button
                    appearance="primary"
                    disabled={!formik.dirty}
                    isLoading={formik.isSubmitting}
                    onClick={formik.submitForm}
                >
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EmailConfigModal;
