import IconButton, { Props } from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg';
import styles from './EmailPreviewButton.module.css';
import cn from 'classnames';

const EmailPreviewButton = ({ className, ...props }: Props) => {
    return (
        <IconButton className={cn(styles.button, className)} {...props}>
            <EmailIcon />
        </IconButton>
    );
};

export default EmailPreviewButton;
