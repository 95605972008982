import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../ReleaseContext';
import { useCallback } from 'react';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';

type HookConfig = {
    releaseId?: number | undefined;
    isImperative?: boolean;
};

export default function useCampaigns({ releaseId, isImperative = false }: HookConfig = {}) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.campaigns);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            dispatch({ type: 'loadCampaigns' });

            try {
                const response = await microwave.getCampaigns({ release_id: releaseId.toString() }, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'campaignsLoadingError', payload: response.data.detail });
                    return response;
                }

                dispatch({ type: 'campaignsInitialized', payload: response.data.results });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'campaignsLoadingError', payload: 'Could not load campaigns' });
                throw e;
            }
        },
        [dispatch]
    );

    const areCampaignsInitialized = state.status === 'success' || state.status === 'error';
    useAbortableEffect(
        (signal) => {
            if (releaseId === undefined || areCampaignsInitialized || isImperative) {
                return;
            }

            fetchData(releaseId, { signal }).catch(() => {});
        },
        [areCampaignsInitialized, fetchData, isImperative, releaseId]
    );

    const reset = useCallback(() => dispatch({ type: 'resetCampaigns' }), [dispatch]);

    const updateCampaign = useCallback(
        async (campaignId: number, data: microwave.PatchCampaignData) => {
            const response = await microwave.patchCampaign(campaignId, data);
            if (response.status === 200) {
                dispatch({ type: 'updateCampaign', payload: response.data });
            }

            return response;
        },
        [dispatch]
    );

    return {
        ...state,
        fetchData,
        reset,
        updateCampaign,
    };
}
