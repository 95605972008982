import { microwave } from '@round/api';
import { useCallback, useState } from 'react';

export type Params = Parameters<typeof microwave.getTiktokMicrowaveInfluencerPosts>[0];

export default function useMicrowaveTiktokPosts({
    page,
    page_size,
    payment_request_status,
    payment_requested_at_start,
    payment_requested_at_end,
    audio_id,
    payment_status,
    plan_id,
    ordering,
    payment_deadline_start,
    payment_deadline_end,
    preferred_payment_method,
    influencer_id,
    invite_id,
}: Params) {
    const [posts, setPosts] = useState<microwave.TiktokMicrowaveInfluencerPost[]>([]);
    const [totals, setTotals] = useState<microwave.TiktokMicrowaveInfluencerPostTotals | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [count, setCount] = useState(0);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            setHasError(false);
            try {
                const response = await microwave.getTiktokMicrowaveInfluencerPosts(
                    {
                        page,
                        page_size,
                        payment_request_status,
                        audio_id,
                        payment_status,
                        plan_id,
                        payment_requested_at_start,
                        payment_requested_at_end,
                        ordering,
                        payment_deadline_start,
                        payment_deadline_end,
                        preferred_payment_method,
                        influencer_id,
                        invite_id,
                    },
                    requestInit
                );
                setPosts(response.data.results);
                setTotals(response.data.totals);
                setCount(response.data.count);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setHasError(true);
            }
        },
        [
            page,
            page_size,
            payment_request_status,
            audio_id,
            payment_status,
            plan_id,
            payment_requested_at_start,
            payment_requested_at_end,
            ordering,
            payment_deadline_start,
            payment_deadline_end,
            preferred_payment_method,
            influencer_id,
            invite_id,
        ]
    );

    const removePost = useCallback(
        (paymentRequestId: number) => {
            setTotals((totals) => {
                if (!totals) {
                    return null;
                }

                const post = posts.find((p) => p.payment_request_id === paymentRequestId);

                if (!post) {
                    return totals;
                }

                const newTotals = { ...totals };
                newTotals.cost[Number(post.currency_id)] -= post.cost;

                return newTotals;
            });

            setPosts((posts) => posts.filter((p) => p.payment_request_id !== paymentRequestId));
            setCount((count) => count - 1);
        },
        [posts]
    );

    const reset = useCallback(() => {
        setPosts([]);
        setIsInitialized(false);
        setHasError(false);
    }, []);

    return {
        posts,
        totals,
        count,
        isInitialized,
        hasError,
        isLoading: !isInitialized && !hasError,
        init,
        reset,
        removePost,
    };
}
