import InvitesTable, {
    InvitesTableRow,
    Meta as InvitesMeta,
    Props as InvitesTableProps,
} from '../../../InvitesTable/InvitesTable';
import { ColumnDef } from '@tanstack/react-table';
import { getTableMetaHelper, Skeleton, SkeletonTableCell } from '@round/ui-kit';
import { InstagramAudio, InstagramUserImage, microwave } from '@round/api';
import moment from 'moment';
import styles from './InstagramPostedTable.module.css';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import AccountCell, {
    Meta as AccountCellMeta,
    AccountData,
} from 'Modules/Advertising/Microwave/components/cells/AccountCell/AccountCell';
import { buildInstagramUserUrl } from 'helpers';

export type InstagramPostedTableRow = InvitesTableRow & {
    creator: microwave.MicrowaveInfluencer | null;
    audio: InstagramAudio | null;
    image: InstagramUserImage | null;
};

type Meta = InvitesMeta & AccountCellMeta;
const getTableMeta = getTableMetaHelper<Meta>();

type Props = Omit<InvitesTableProps<InstagramPostedTableRow, Meta>, 'columns'>;
const InstagramPostedTable = (props: Props) => {
    const columns: ColumnDef<InstagramPostedTableRow, any>[] = [
        {
            id: 'account',
            header: 'Account',
            accessorFn: (row): AccountData => ({
                image: row.image?.avatar_thumb.cached_url || row.image?.avatar_thumb.original_url || '',
                profileUrl: row.creator?.instagram_user_username
                    ? buildInstagramUserUrl(row.creator.instagram_user_username)
                    : '',
                title: row.creator?.instagram_user_username ?? '-',
                username: row.creator?.instagram_user_username ?? '-',
            }),
            cell: AccountCell,
        },
        {
            header: 'Post',
            accessorKey: 'post_create_time',
            enableSorting: true,
            cell: ({ getValue, table, row }) => {
                const { isLoading } = getTableMeta(table);
                if (isLoading) {
                    return <Skeleton />;
                }

                const createdAt = getValue();
                return (
                    <div className={styles.post}>
                        {createdAt ? moment(createdAt).format('DD MMM YYYY [at] HH:mm') : '-'}
                        {row.original.post_url && (
                            <a
                                className={styles.postLink}
                                href={row.original.post_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon className={styles.postLinkIcon} />
                            </a>
                        )}
                    </div>
                );
            },
        },
        {
            id: 'audio',
            header: 'Audio',
            accessorFn: (row) => (row.audio ? row.audio.title : '-'),
            cell: SkeletonTableCell,
        },
        {
            id: 'last_contacted_at',
            header: 'Last contacted at',
            enableSorting: true,
            accessorFn: (row) =>
                row.last_contacted_at ? moment(row.last_contacted_at).format('DD MMM YYYY [at] HH:mm') : '-',
            cell: SkeletonTableCell,
        },
    ];

    return <InvitesTable {...props} enableSorting manualSorting columns={columns} />;
};

export default InstagramPostedTable;
