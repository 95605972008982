import { patchTikTokAudio } from './TikTok.api';
import { getTiktokAudios, postTikTokAudio, TiktokAudio } from '@round/api';

export async function updateTikTokAudioAlternateName(musicId: number, alternateName: string) {
    try {
        return await patchTikTokAudio(musicId, { alternate_name: alternateName });
    } catch (e) {
        if (e instanceof Error && e.name === 'AbortError') {
            throw e;
        }

        throw new Error('Could not set custom name');
    }
}

type FindOrCreateTikTokAudioReturn = { success: true; data: TiktokAudio } | { success: false; error: string };

export async function findOrCreateTiktokAudio(identifier: string): Promise<FindOrCreateTikTokAudioReturn> {
    const findResponse = await getTiktokAudios({ identifier_search: identifier });
    if (findResponse.status !== 200) {
        return { success: false, error: 'Error occurred on audio search' };
    }

    let audio: TiktokAudio | null = findResponse.data.results[0] ?? null;
    if (audio) {
        return { success: true, data: audio };
    }

    const postResponse = await postTikTokAudio({ identifier });
    if (postResponse.status === 201) {
        return { success: true, data: postResponse.data };
    }

    const message = Array.isArray(postResponse.data.identifier)
        ? postResponse.data.identifier.toString()
        : postResponse.data.identifier ?? 'Could not create audio';

    return { success: false, error: message };
}
