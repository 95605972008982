import { microwave } from '@round/api';
import { SkeletonTableCell } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import DecisionCell, { Meta as DecisionCellMeta } from './components/DecisionCell/DecisionCell';
import styles from './ApproveInstagramPostsTable.module.css';

export type Row = microwave.PaymentRequest & {
    influencer: microwave.MicrowaveInfluencer | null;
};

type Meta = {
    isLoading?: boolean;
} & DecisionCellMeta;

export type Props = Pick<
    WrapperPaginationTableProps<Row>,
    'data' | 'page' | 'setPage' | 'setPageSize' | 'pageSize' | 'count'
> & {
    meta: Meta;
};

const ApproveInstagramPostsTable = <TRow extends Row>({ data, ...props }: Props) => {
    const columns: ColumnDef<Row, any>[] = [
        //this column probably won't exist in the final table
        {
            id: 'id',
            header: 'Invite ID',
            accessorKey: 'invite_id',
            cell: SkeletonTableCell,
        },
        {
            id: 'influencerEmail',
            header: 'Influencer Email',
            accessorFn: (row) => row.influencer?.email || '-',
            cell: SkeletonTableCell,
        },
        {
            id: 'decision',
            header: 'Decision',
            meta: {
                className: styles.decisionColumn,
            },
            accessorKey: 'status',
            cell: DecisionCell,
        },
    ];

    const rows: TRow[] = props.meta.isLoading && !data.length ? new Array(props.pageSize).fill(() => ({})) : data;

    return <WrapperPaginationTable manualPagination data={rows} columns={columns} {...props} />;
};

export default ApproveInstagramPostsTable;
