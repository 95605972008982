import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import moment from 'moment';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = CellContext<T, string | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableUploadedDateCell = <T extends Row>({ table, row }: Context<T>) => {
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    const uploadedAt = row.original.upload_date;

    if (typeof uploadedAt !== 'string') {
        return '-';
    }

    return moment(uploadedAt).format('DD MMM YYYY');
};

export default PostsTableUploadedDateCell;
