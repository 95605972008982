import InvitesTable, {
    InvitesTableRow,
    Props as InvitesTableProps,
    Meta as InvitesMeta,
} from '../../../InvitesTable/InvitesTable';
import { ColumnDef } from '@tanstack/react-table';
import { SelectCell, SelectHeader } from 'ui-new/TableComponents/SelectCell/SelectCell';
import { getTableMetaHelper, Skeleton, SkeletonTableCell } from '@round/ui-kit';
import { InstagramAudio, InstagramUserImage, microwave } from '@round/api';
import moment from 'moment';
import { MICROWAVE_APP_DOMAIN } from '../../../../../MicroCreators/helpers';
import styles from './InstagramContactedTable.module.css';
import AccountCell, {
    AccountData,
    Meta as AccountCellMeta,
} from 'Modules/Advertising/Microwave/components/cells/AccountCell/AccountCell';
import { buildInstagramUserUrl } from 'helpers';

export type InstagramContactedTableRow = InvitesTableRow & {
    creator: microwave.MicrowaveInfluencer | null;
    audio: InstagramAudio | null;
    image: InstagramUserImage | null;
};

type Meta = InvitesMeta & AccountCellMeta;
const getTableMeta = getTableMetaHelper<Meta>();

type Props = Omit<InvitesTableProps<InstagramContactedTableRow, Meta>, 'columns'>;
const InstagramContactedTable = (props: Props) => {
    const columns: ColumnDef<InstagramContactedTableRow, any>[] = [
        {
            id: 'select',
            header: SelectHeader,
            cell: SelectCell,
        },
        {
            id: 'account',
            header: 'Account',
            accessorFn: (row): AccountData => ({
                image: row.image?.avatar_thumb.cached_url || row.image?.avatar_thumb.original_url || '',
                profileUrl: row.creator?.instagram_user_username
                    ? buildInstagramUserUrl(row.creator.instagram_user_username)
                    : '',
                title: row.creator?.instagram_user_username ?? '-',
                username: row.creator?.instagram_user_username ?? '-',
            }),
            cell: AccountCell,
        },
        {
            id: 'audio',
            header: 'Audio',
            accessorFn: (row) => (row.audio ? row.audio.title : '-'),
            cell: SkeletonTableCell,
        },
        {
            id: 'deadline',
            header: 'Deadline',
            accessorFn: (row) => moment(row.deadline).format('DD MMM YYYY'),
            cell: SkeletonTableCell,
        },
        {
            header: '# of chase emails',
            accessorKey: 'chase_email_count',
            enableSorting: true,
            cell: SkeletonTableCell,
        },
        {
            id: 'last_contacted_at',
            header: 'Last contacted at',
            enableSorting: true,
            accessorFn: (row) =>
                row.last_contacted_at ? moment(row.last_contacted_at).format('DD MMM YYYY [at] HH:mm') : '-',
            cell: SkeletonTableCell,
        },
        {
            id: 'paymentRequestForm',
            header: 'Payment request form',
            accessorKey: 'id',
            cell: ({ getValue, table }) => {
                const { isLoading } = getTableMeta(table);
                if (isLoading) {
                    return <Skeleton />;
                }

                const id = getValue();
                return (
                    <a href={`${MICROWAVE_APP_DOMAIN}/submit-post/${id}/`} target="_blank" rel="noopener noreferrer">
                        Link
                    </a>
                );
            },
        },
    ];

    return (
        <InvitesTable
            {...props}
            className={styles.table}
            enableSorting
            manualSorting
            enableRowSelection
            enableMultiRowSelection
            getRowId={(row, index) => (row.id ? `${row.id}` : `skeleton-${index}`)}
            columns={columns}
        />
    );
};

export default InstagramContactedTable;
