import styles from './CampaignDetails.module.css';
import { ReactComponent as ArrowRightIcon } from 'assets/ArrowRight.svg';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { Image, TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import Skeleton from 'react-loading-skeleton';
import { microwave } from '@round/api';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTok.svg';
import useRelease from './Release/useRelease';
import useMicrowaveContentTags from './Creators/useMicrowaveContentTags';
import { useAbortableEffect } from '@round/utils';
import InstagramCampaigns from './Campaigns/InstagramCampaigns/InstagramCampaigns';
import TiktokCampaigns from './Campaigns/TiktokCampaigns/TiktokCampaigns';
import UnderLineTabs from 'ui-new/Tabs/UnderlineTabs/UnderLineTabs';

type Props = {
    releaseId: number;
};

const getPlatform = (hash: string): microwave.Platform | undefined => {
    if (!hash) {
        return;
    }

    const platform = hash.slice(1);
    return microwave.isPlatform(platform) ? platform : undefined;
};

const CampaignDetails = ({ releaseId }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const activePlatform = getPlatform(location.hash);
    const setActivePlatform = (platform: microwave.Platform | undefined) => {
        navigate({ hash: `#${platform}` }, { replace: true });
    };

    const { data: release, status: releaseStatus } = useRelease(releaseId);

    const { status: microwaveContentTagsStatus, fetchData: fetchMicrowaveContentTags } = useMicrowaveContentTags();
    const areMicrowaveContentTagsInitialized =
        microwaveContentTagsStatus === 'error' || microwaveContentTagsStatus === 'success';
    useAbortableEffect(
        (signal) => {
            if (!areMicrowaveContentTagsInitialized) {
                fetchMicrowaveContentTags({ signal }).catch(() => {});
            }
        },
        [fetchMicrowaveContentTags, areMicrowaveContentTagsInitialized]
    );

    if (!activePlatform) {
        return <Navigate to="#instagram" replace />;
    }

    const isReleaseLoading = releaseStatus === 'idle' || releaseStatus === 'loading';
    return (
        <main className={styles.container}>
            <div className={styles.actions}>
                <IconButton className={styles.goBackButton} onClick={() => navigate(-1)}>
                    <ArrowRightIcon className={styles.icon} />
                </IconButton>
            </div>

            <section className={styles.releaseSection}>
                <Image
                    className={styles.releaseImage}
                    src={release?.brand_image}
                    loading={isReleaseLoading}
                    skeletonCircle={false}
                />

                <div className={styles.releaseInfoContainer}>
                    <div className={styles.releaseTitles}>
                        <h1 className={styles.releaseTitle}>
                            {isReleaseLoading ? <Skeleton width="15rem" /> : release?.name}
                        </h1>
                        <p className={styles.releaseSubTitle}>
                            {isReleaseLoading ? (
                                <Skeleton width="10rem" />
                            ) : (
                                `${release?.brand_name} | ${release?.client_name}`
                            )}
                        </p>
                    </div>
                </div>
            </section>

            <section className={styles.campaignsSection}>
                <Tabs activeTab={activePlatform} onChange={setActivePlatform}>
                    <UnderLineTabs.Bar className={styles.tabNavigation}>
                        <UnderLineTabs.Item name="instagram">
                            <InstagramIcon className={styles.platformIcon} /> Instagram
                        </UnderLineTabs.Item>
                        <UnderLineTabs.Item name="tiktok">
                            <TikTokIcon className={styles.platformIcon} /> TikTok
                        </UnderLineTabs.Item>
                    </UnderLineTabs.Bar>

                    <TabPanels>
                        <TabPanel name="instagram">
                            <InstagramCampaigns releaseId={releaseId} />
                        </TabPanel>
                        <TabPanel name="tiktok">
                            <TiktokCampaigns releaseId={releaseId} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </section>
        </main>
    );
};

export default CampaignDetails;
