import { getInstagramUserImages, getTiktokUserImages, microwave, TiktokUserImage, youtube } from '@round/api';
import { ReactComponent as TiktokIcon } from 'assets/icons/platform/TikTok.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/Youtube.svg';
import { InstagramUserImage } from 'App.types';
import { isNumber } from 'lodash';

export const MICROWAVE_PLATFORMS: microwave.Platform[] = ['tiktok', 'instagram', 'youtube'];

export const microwavePlatformLabels = {
    tiktok: 'TikTok',
    instagram: 'Instagram',
    youtube: 'YouTube',
};

export const getMicrowavePlatformLabel = (platform: microwave.Platform) => microwavePlatformLabels[platform];

type InfluencerPlatformUsernameKey = keyof Pick<
    microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    'instagram_user_username' | 'tiktok_user_unique_id' | 'youtube_channel_title'
>;

const InfluencerUsernameKeyMap: { [key in microwave.Platform]?: InfluencerPlatformUsernameKey } = {
    tiktok: 'tiktok_user_unique_id',
    instagram: 'instagram_user_username',
    youtube: 'youtube_channel_title',
};

export const getMicrowaveInfluencerPlatformUsername = (
    influencer: microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    platform: microwave.Platform
): string | null => {
    const key = InfluencerUsernameKeyMap[platform];
    if (!key) {
        return null;
    }

    return influencer[key];
};

type InfluencerPlatformPlatformIdKey = keyof Pick<
    microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    'instagram_user_username' | 'tiktok_user_unique_id' | 'youtube_channel_youtube_id'
>;

const InfluencerUsernamePlatformIdKeyMap: { [key in microwave.Platform]?: InfluencerPlatformPlatformIdKey } = {
    tiktok: 'tiktok_user_unique_id',
    instagram: 'instagram_user_username',
    youtube: 'youtube_channel_youtube_id',
};

export const getMicrowaveInfluencerPlatformId = (
    influencer: microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    platform: microwave.Platform
): string | null => {
    const key = InfluencerUsernamePlatformIdKeyMap[platform];
    if (!key) {
        return null;
    }

    return influencer[key];
};

const platformIcons: {
    [key in microwave.Platform]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
    tiktok: TiktokIcon,
    instagram: InstagramIcon,
    youtube: YoutubeIcon,
};

export const getPlatformIcon = (platform: microwave.Platform) => {
    return platformIcons[platform];
};

export const fetchPlatformImageData = async (
    influencers: microwave.MicrowaveInfluencer[],
    requestInit?: RequestInit
): Promise<[TiktokUserImage[], InstagramUserImage[], youtube.Channel[]]> => {
    const tiktokUserIds = influencers.map((inf) => inf.tiktok_user_id).filter(isNumber);
    const instagramUserIds = influencers.map((inf) => inf.instagram_user_id).filter(isNumber);
    const youtubeChannelIds = influencers.map((inf) => inf.youtube_channel_id).filter(isNumber);

    return Promise.allSettled([
        tiktokUserIds.length ? getTiktokUserImages(tiktokUserIds, requestInit) : Promise.resolve([]),
        instagramUserIds
            ? getInstagramUserImages(instagramUserIds, requestInit).then((res) => res.data)
            : Promise.resolve([]),
        youtubeChannelIds.length
            ? youtube
                  .getChannels({ id: youtubeChannelIds.join(','), page_size: youtubeChannelIds.length })
                  .then((res) => (res.status === 200 ? res.data.results : []))
            : Promise.resolve([]),
    ]).then(([tiktokResult, instagramResult, youtubeResult]) => [
        tiktokResult.status === 'fulfilled' ? tiktokResult.value : [],
        instagramResult.status === 'fulfilled' ? instagramResult.value : [],
        youtubeResult.status === 'fulfilled' ? youtubeResult.value : [],
    ]);
};

export type InfluencerPlatformImageData = {
    tiktok: TiktokUserImage[];
    instagram: InstagramUserImage[];
    youtube: youtube.Channel[];
};

export const getInfluencerImageUrls = (
    influencer: microwave.MicrowaveInfluencer,
    imageData: InfluencerPlatformImageData
) => {
    const tiktokImage = imageData.tiktok.find((image) => image.user_id === influencer.tiktok_user_id);
    const instagramImage = imageData.instagram.find((image) => image.user_id === influencer.instagram_user_id);
    const youtubeChannel = imageData.youtube.find((channel) => channel.id === influencer.youtube_channel_id);

    return {
        tiktok: tiktokImage?.avatar_thumb.cached_url ?? tiktokImage?.avatar_thumb.original_url,
        instagram: instagramImage?.avatar_thumb.cached_url ?? instagramImage?.avatar_thumb.original_url,
        youtube: youtubeChannel?.thumbnail ?? '',
    };
};
