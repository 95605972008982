import { getTableMetaHelper, Image, SkeletonizedValue } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import styles from './AccountCell.module.css';

export type AccountData = {
    image: string | undefined | null;
    title: string;
    username: string;
    profileUrl: string;
};

export type Meta<TRow extends object = {}> = {
    isLoading: boolean;
    getIsAccountDataLoading: (row: TRow) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();
const AccountCell = <T extends object = {}>({ table, getValue, row: { original } }: CellContext<T, AccountData>) => {
    const { isLoading, getIsAccountDataLoading } = getTableMeta(table);
    const account = getValue();
    const isAccountDataLoading = isLoading || getIsAccountDataLoading?.(original);

    return (
        <div className={styles.container}>
            <Image
                className={styles.image}
                src={account?.image || ''}
                alt={account?.username}
                loading={isAccountDataLoading}
            />
            <div className={styles.titles}>
                <span className={styles.title}>
                    <SkeletonizedValue width="10rem" isInitialized={!isAccountDataLoading}>
                        {account?.title || '-'}
                    </SkeletonizedValue>
                </span>
                <SkeletonizedValue isInitialized={!isAccountDataLoading} width="7rem">
                    <a className={styles.subTitle} href={account?.profileUrl} target="_blank" rel="noopener noreferrer">
                        {account?.username ? `@${account.username}` : '-'}
                    </a>
                </SkeletonizedValue>
            </div>
        </div>
    );
};

export default AccountCell;
