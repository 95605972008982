import { call, encodeUrlSearchParams } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { Invite } from './invites.types';

export type GetInvitesOrderingParams = 'chase_email_count' | 'last_contacted_at' | 'post_create_time';
export type GetInvitesParams = Partial<
    PaginatedRequest & {
        campaign_id: number;
        is_posted: boolean;
        ordering: OrderingValues<GetInvitesOrderingParams>;
        chase_email_count: number;
        id: string;
    }
>;
type GetInvitesResponse =
    | ApiResponse<PaginatedApiResponseData<Invite>, 200>
    | ApiResponse<ApiResponseError<Invite>, 400>
    | ApiResponse<string[], 400>
    | NotFoundResponse;

export async function getInvites(params: GetInvitesParams, requestInit?: RequestInit): Promise<GetInvitesResponse> {
    const response = await call(`/api/microwave/invites/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get microwave invites');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchInviteData = Partial<Pick<Invite, 'deadline'>>;
type PatchInviteResponse =
    | ApiResponse<Invite, 200>
    | ApiResponse<ApiResponseError<PatchInviteData>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchInvite(id: string, data: PatchInviteData): Promise<PatchInviteResponse> {
    const response = await call(`/api/microwave/invites/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update invite');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
