import { TiktokUser, creatorbase, TiktokUserImage, TiktokUserStats } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper, SkeletonTableCell } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './PostsTable.module.css';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import { UsePostsReturn } from 'Modules/Plans/Project/contexts/PostsContext';
import { CampaignTableRow } from '../ProjectCampaignsTable/ProjectCampaignsTable';
import TotalFooterCell from '../../../components/TotalFooterCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import PostsNoDataLabel from '../PostsNoDataLabel/PostsNoDataLabel';
import ActionsCell from './components/ActionsCell/ActionsCell';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import Image from '../../../../../../ui/Image/Image';
import { formatNumberToKNotation } from 'helpers';
import { getTiktokEngagementRatePercentageString, getTikTokTotalEngagement } from 'Modules/Plans/Posts/helpers';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { useCampaignStats } from 'Modules/Plans/Project/contexts/CampaignStatsContext/CampaignsStatsContext';
import { useTiktokAccountsData } from 'Modules/Plans/Project/contexts/AccountDataContext/TiktokAccountsContext/TiktokAccountsContext';
import UpdateInfluencerPopout from './components/UpdateInfluencerPopout/UpdateInfluencerPopout';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostsTableEngagementsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementsFooterCell';
import PostsTableEngagementRateFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementRateFooterCell';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';

export type TiktokPostsTableRow = creatorbase.TiktokPost & {
    user?: TiktokUser | null;
    userStats?: TiktokUserStats | null;
    image?: TiktokUserImage | null;
};

type Props = Pick<TableProps<TiktokPostsTableRow>, 'data' | 'noDataLabel'> & {
    campaign: CampaignTableRow;
    isLoading?: boolean;
    hasError: boolean;
    onCreatePost: (campaign: CampaignTableRow) => void;
    onDeletePost: (post: TiktokPostsTableRow) => void;
    updatePost: UsePostsReturn['updatePost'];
};

type TableCellContext<TKey extends keyof TiktokPostsTableRow> = CellContext<
    TiktokPostsTableRow,
    TiktokPostsTableRow[TKey]
>;

type Meta = BasePostTableMeta<TiktokPostsTableRow> & {
    campaign: CampaignTableRow;
    onDeletePost: (post: TiktokPostsTableRow) => void;
};
const getTableMeta = getTableMetaHelper<Meta>();

const TiktokPostsTable = ({ data, campaign, isLoading, hasError, updatePost, onCreatePost, onDeletePost }: Props) => {
    const { getIsLoading: getAreCampaignStatsLoading } = useCampaignStats();
    const { getIsLoading: getIsAccountDataLoading } = useTiktokAccountsData();

    const columns = useMemo<ColumnDef<TiktokPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                id: 'account',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, getIsAccountDataLoading, updatePost } = getTableMeta(table);
                    const image =
                        original?.image?.avatar_thumb?.cached_url || original?.image?.avatar_thumb?.original_url || '';
                    const followerCount = original?.userStats?.follower_count;

                    if (isLoading || getIsAccountDataLoading(original)) {
                        return <Skeleton />;
                    }

                    const accountHandle = original.user?.unique_id;

                    return (
                        <div className={styles.accountContainer}>
                            <Image loading={isLoading} src={image} className={styles.accountImage} />
                            <div className={styles.accountDetails}>
                                <p>{accountHandle ? `@${accountHandle}` : '-'}</p>
                                <p className={styles.followerCount}>
                                    {typeof followerCount === 'number' ? formatNumberToKNotation(followerCount) : '-'}
                                </p>
                            </div>

                            {!original.post_url && (
                                <UpdateInfluencerPopout
                                    key={accountHandle}
                                    className={styles.editAccountButton}
                                    accountHandle={accountHandle ?? ''}
                                    updateAccountHandle={
                                        updatePost
                                            ? (accountHandle) =>
                                                  updatePost?.(original.id, {
                                                      influencer_account_identifier: accountHandle,
                                                  })
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    adCode: original.tiktok_details?.ad_code,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'cost'>) => {
                    const { isLoading, updatePost, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <CostCell
                            cost={getValue()}
                            currency={campaign.currency ?? null}
                            updateCost={
                                updatePost ? (cost: number | null) => updatePost(original.id, { cost }) : undefined
                            }
                            isReadonly={!campaign.is_post_cost_editable}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Uploaded date',
                id: 'uploadedDate',
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                accessorKey: 'view_count',
                id: 'views',
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Total engagements',
                id: 'totalEngagements',
                accessorFn: (row) => getTikTokTotalEngagement(row),
                cell: PostStatsValueCell,
                footer: PostsTableEngagementsFooterCell,
            },
            {
                header: 'Engagement rate',
                id: 'engagementRate',
                accessorFn: (row) => getTiktokEngagementRatePercentageString(row),
                cell: SkeletonTableCell,
                footer: PostsTableEngagementRateFooterCell,
            },
            {
                header: 'Likes',
                id: 'likes',
                accessorFn: (row) => row.tiktok_details?.like_count,
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.tiktok_details?.like_count)}
                        />
                    );
                },
            },
            {
                header: 'Shares',
                id: 'shares',
                accessorFn: (row) => row.tiktok_details?.share_count,
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.tiktok_details?.share_count)}
                        />
                    );
                },
            },
            {
                header: 'Comments',
                id: 'comments',
                accessorFn: (row) => row.tiktok_details?.comment_count,
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.tiktok_details?.comment_count)}
                        />
                    );
                },
            },
            {
                header: 'Saves',
                id: 'saves',
                accessorFn: (row) => row.tiktok_details?.save_count,
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.tiktok_details?.save_count)}
                        />
                    );
                },
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'notes'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return (
                        <NotesCell
                            notes={getValue()}
                            updateNotes={updatePost ? (notes: string) => updatePost(original.id, { notes }) : undefined}
                        />
                    );
                },
            },
            {
                header: '',
                id: 'actions',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, onDeletePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <ActionsCell onDelete={() => onDeletePost(original)} />;
                },
            },
        ],
        []
    );

    // meta object is passed to every cell component. This allows us to reduce rerenders and prevents unnecessary cell unmounts.
    const meta: Meta = {
        isLoading: !!isLoading,
        getIsAccountDataLoading: (row) =>
            row.tiktok_details?.account_id ? getIsAccountDataLoading(row.tiktok_details.account_id) : false,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
        onDeletePost,
        updatePost,
        campaign,
        campaignStats: campaign.stats,
    };

    return (
        <PostsTable
            className={styles.table}
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            noDataLabel={<PostsNoDataLabel hasError={hasError} onAddPost={() => onCreatePost(campaign)} />}
        />
    );
};

export default TiktokPostsTable;
