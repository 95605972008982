import { DataState, useAbortableEffect } from '@round/utils';
import { microwave } from '@round/api';
import { ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { makeLoadingStateSlice, makeErrorStateSlice, makeSuccessStateSlice } from 'utility/dataState';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';

type State = {
    [creatorId: number]: DataState<microwave.MicrowaveInfluencer | null> | undefined;
};

type Actions =
    | ReducerActionWithPayload<'creatorsByIdDataHookLoad', number[]>
    | ReducerActionWithPayload<
          'creatorsByIdDataHookInit',
          { creatorIds: number[]; data: microwave.MicrowaveInfluencer[] }
      >
    | ReducerActionWithPayload<'creatorsByIdDataHookError', { creatorIds: number[]; message: string }>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    creatorsByIdDataHookLoad: (state, { payload: creatorIds }) => ({ ...state, ...makeLoadingStateSlice(creatorIds) }),
    creatorsByIdDataHookError: (state, { payload: { creatorIds, message } }) => ({
        ...state,
        ...makeErrorStateSlice(creatorIds, message),
    }),
    creatorsByIdDataHookInit: (state, { payload: { creatorIds, data } }) => ({
        ...state,
        ...makeSuccessStateSlice(creatorIds, (key) => data.find((c) => c.id === key) ?? null),
    }),
});

//should convert this to accept string query instead of array of ids
export default function makeCreatorsByIdDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function useCreatorsById({
        ids,
        isDisabled: isDisabledProp,
    }: Partial<{ ids: number[]; isDisabled: boolean | ((state: State) => boolean) }>) {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const isDisabled = typeof isDisabledProp === 'function' ? isDisabledProp(state) : isDisabledProp;

        const fetchData = useCallback(
            async (ids: number[], requestInit?: RequestInit) => {
                dispatch({ type: 'creatorsByIdDataHookLoad', payload: ids });
                try {
                    const response = await microwave.getMicrowaveInfluencers(
                        { id: ids.toString(), page_size: ids.length, page: 1 },
                        requestInit
                    );

                    if (response.status !== 200) {
                        dispatch({
                            type: 'creatorsByIdDataHookError',
                            payload: { creatorIds: ids, message: 'Could not load microwave influencers' },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'creatorsByIdDataHookInit',
                        payload: { creatorIds: ids, data: response.data.results },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'creatorsByIdDataHookError',
                        payload: { creatorIds: ids, message: 'Could not load microwave influencers' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        //stabilize ids query
        const idsQuery = ids?.join(',');
        useAbortableEffect(
            (signal) => {
                if (isDisabled) {
                    return;
                }

                fetchData(idsQuery?.split(',').map(Number) ?? [], { signal }).catch(() => {});
            },
            [fetchData, idsQuery, isDisabled]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
