import { microwave } from '@round/api';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';

export type InvitesTableRow = microwave.Invite;

export type Props<TRow extends InvitesTableRow, TMeta extends Meta = Meta> = Pick<
    WrapperPaginationTableProps<TRow>,
    | 'columns'
    | 'data'
    | 'page'
    | 'setPage'
    | 'pageSize'
    | 'setPageSize'
    | 'count'
    | 'noDataLabel'
    | 'rowSelection'
    | 'onRowSelectionChange'
    | 'enableMultiRowSelection'
    | 'enableRowSelection'
    | 'getRowId'
    | 'className'
    | 'enableSorting'
    | 'sorting'
    | 'onSortingChange'
    | 'manualSorting'
> & {
    meta: TMeta;
};

export type Meta = {
    isLoading: boolean;
};

const InvitesTable = <TRow extends InvitesTableRow>({ data, ...props }: Props<TRow>) => {
    const rows = props.meta.isLoading && !data.length ? new Array(props.pageSize).fill(() => ({})) : data;
    return <WrapperPaginationTable manualPagination data={rows} {...props} />;
};

export default InvitesTable;
