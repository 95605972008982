import SearchInput from 'ui-new/SearchInput/SearchInput';
import styles from './InstagramCreatorsFilters.module.css';
import { microwave } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import {
    ContentTagsFilter,
    ContentTagsFilterIcon,
} from 'Modules/Tags/components/Filters/ContentTagsFilter/ContentTagsFilter';
import { ReactComponent as BaseHashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as BasePostIcon } from 'assets/icons/Post.svg';
import { ReactComponent as BaseMailIcon } from 'assets/EmailSend.svg';
import { ReactComponent as BaseTickIcon } from 'assets/TickCircle.svg';
import { Filter } from 'ui-new/Filters/useFilterManager';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
    stringifyConditionsToUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import { conditionTypeOptionsWithExclude } from '@round/ui-kit';
import { isEqual } from 'lodash';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import { SetStateAction } from 'react';
import useMicrowaveContentTags from '../../useMicrowaveContentTags';
import useTiktokHashtagSelect, { TiktokHashtagOption } from 'Modules/TikTok/hooks/useTiktokHashtagSelect';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { ValueType } from 'react-select';
import RatingFilter, { RatingFilterIcon } from 'ui-new/Filters/RatingFilter/RatingFilter';
import { getRatingOptions } from 'ui/RatingSelect/RatingSelect';
import { GenericDropdownOption } from 'App.types';
import MinMaxFilter from 'ui-new/Filters/MinMaxFilter/MinMaxFilter';
import { DebounceInput } from 'react-debounce-input';

type FilterParams = Pick<
    microwave.GetMicrowaveInfluencersParams,
    | 'search'
    | 'content_tags'
    | 'hashtag_ids'
    | 'rating'
    | 'min_tiktok_post_count'
    | 'max_tiktok_post_count'
    | 'min_instagram_post_count'
    | 'max_instagram_post_count'
    | 'min_tiktok_post_invite_count'
    | 'max_tiktok_post_invite_count'
    | 'min_instagram_post_invite_count'
    | 'max_instagram_post_invite_count'
    | 'min_tiktok_post_rate'
    | 'min_instagram_post_rate'
>;

type Props = Omit<DomainFiltersProps<FilterParams>, 'onChange'> & {
    onChange: (setter: SetStateAction<FilterParams>, shouldRefreshData?: boolean) => void;
};

export const getFilterValues = (
    urlState: Partial<Record<keyof microwave.GetMicrowaveInfluencersParams, string>>
): FilterParams => {
    return {
        search: urlState.search,
        content_tags: urlState.content_tags,
        hashtag_ids: urlState.hashtag_ids,
        rating: urlState.rating,
        min_tiktok_post_count:
            urlState.min_tiktok_post_count !== undefined ? Number(urlState.min_tiktok_post_count) : undefined,
        max_tiktok_post_count:
            urlState.max_tiktok_post_count !== undefined ? Number(urlState.max_tiktok_post_count) : undefined,
        min_instagram_post_count:
            urlState.min_instagram_post_count !== undefined ? Number(urlState.min_instagram_post_count) : undefined,
        max_instagram_post_count:
            urlState.max_instagram_post_count !== undefined ? Number(urlState.max_instagram_post_count) : undefined,
        min_tiktok_post_invite_count:
            urlState.min_tiktok_post_invite_count !== undefined
                ? Number(urlState.min_tiktok_post_invite_count)
                : undefined,
        max_tiktok_post_invite_count:
            urlState.max_tiktok_post_invite_count !== undefined
                ? Number(urlState.max_tiktok_post_invite_count)
                : undefined,
        min_instagram_post_invite_count:
            urlState.min_instagram_post_invite_count !== undefined
                ? Number(urlState.min_instagram_post_invite_count)
                : undefined,
        max_instagram_post_invite_count:
            urlState.max_instagram_post_invite_count !== undefined
                ? Number(urlState.max_instagram_post_invite_count)
                : undefined,
        min_tiktok_post_rate:
            urlState.min_tiktok_post_rate !== undefined ? Number(urlState.min_tiktok_post_rate) : undefined,
        min_instagram_post_rate:
            urlState.min_instagram_post_rate !== undefined ? Number(urlState.min_instagram_post_rate) : undefined,
    };
};

const HashIcon = () => <BaseHashIcon className={styles.filterIcon} />;
const PostIcon = () => <BasePostIcon className={styles.filterIcon} />;
const MailIcon = () => <BaseMailIcon className={styles.filterIcon} />;
const TickIcon = () => <BaseTickIcon className={styles.filterIcon} />;

const InstagramCreatorsFilters = ({ value, onChange }: Props) => {
    const { data: contentTags, status: contentTagsStatus } = useMicrowaveContentTags();
    const isContentTagsLoading = contentTagsStatus === 'loading';
    const contentTagsOptions =
        contentTags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
        })) ?? [];

    const { props: tiktokHashtagSelectProps } = useTiktokHashtagSelect({
        initialValueId: value.hashtag_ids?.length ? value.hashtag_ids.split(',').map(Number) : undefined,
    });

    const filters: Filter<keyof FilterParams>[] = [
        {
            name: 'content_tags',
            label: 'Tags',
            icon: () => ContentTagsFilterIcon,
            render(this, helpers) {
                return (
                    <ContentTagsFilter
                        name="Tags"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        options={contentTagsOptions}
                        areOptionsLoading={isContentTagsLoading}
                        onRemove={() => {
                            onChange({
                                content_tags: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        conditions={parseConditionsFromUrlString(value.content_tags)}
                        conditionOptions={conditionTypeOptionsWithExclude}
                        onChange={(conditions) => {
                            const urlTags = parseConditionsFromUrlString(value.content_tags);
                            const shouldRefreshData =
                                !isEqual(
                                    mapConditionsToApi(conditions.filter(isIncludeCondition)),
                                    mapConditionsToApi(urlTags.filter(isIncludeCondition))
                                ) ||
                                !isEqual(
                                    mapExcludeConditionsToApi(conditions.filter(isExcludeCondition)),
                                    mapExcludeConditionsToApi(urlTags.filter(isExcludeCondition))
                                );

                            onChange(
                                {
                                    content_tags: stringifyConditionsToUrlString(conditions),
                                },
                                shouldRefreshData
                            );
                        }}
                    />
                );
            },
        },
        {
            name: 'hashtag_ids',
            label: 'TikTok Hashtags',
            icon: HashIcon,
            render(this, helpers) {
                const clearState = () => {
                    onChange({
                        hashtag_ids: undefined,
                    });
                    tiktokHashtagSelectProps.onChange?.(null, { action: 'clear' });
                };

                return (
                    <SelectFilter
                        icon={<HashIcon />}
                        {...tiktokHashtagSelectProps}
                        name="TikTok Hashtags"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={tiktokHashtagSelectProps.value as ValueType<TiktokHashtagOption, true>}
                        onChange={(options: ValueType<TiktokHashtagOption, true>, action) => {
                            onChange({
                                hashtag_ids: options?.map((option) => option.value).join(','),
                            });
                            tiktokHashtagSelectProps.onChange?.(options, action);
                        }}
                        onRemove={() => {
                            clearState();
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => clearState()}
                        isMulti
                    />
                );
            },
        },
        {
            name: 'rating',
            label: 'Rating',
            icon: RatingFilterIcon,
            render(this, helpers) {
                const maxRating = 3;
                return (
                    <RatingFilter
                        name="Rating"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={getRatingOptions(maxRating).filter((o) =>
                            value.rating?.split(',').includes(o.value.toString())
                        )}
                        onChange={(value: ValueType<GenericDropdownOption<number>, true>) => {
                            onChange({ rating: value?.map((o) => o.value).toString() });
                        }}
                        maxRating={maxRating}
                        onRemove={() => {
                            onChange({ rating: undefined });
                            helpers.remove();
                        }}
                        onClear={() => {
                            onChange({ rating: undefined });
                            helpers.close();
                        }}
                        onClose={helpers.close}
                        isSearchable={false}
                        isMulti
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_count',
            label: 'TikTok Past Posts',
            icon: PostIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_tiktok_post_count}
                        max={value.max_tiktok_post_count}
                        onChange={({ min, max }) => {
                            onChange({ min_tiktok_post_count: min, max_tiktok_post_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_count: undefined,
                                max_tiktok_post_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_count: undefined,
                                max_tiktok_post_count: undefined,
                            });
                            helpers.close();
                        }}
                    />
                );
            },
        },
        {
            name: 'min_instagram_post_count',
            label: 'Instagram Past Posts',
            icon: PostIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_instagram_post_count}
                        max={value.max_instagram_post_count}
                        onChange={({ min, max }) => {
                            onChange({ min_instagram_post_count: min, max_instagram_post_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_count: undefined,
                                max_instagram_post_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_count: undefined,
                                max_instagram_post_count: undefined,
                            });
                            helpers.close();
                        }}
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_invite_count',
            label: 'TikTok Past Invites',
            icon: MailIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_tiktok_post_invite_count}
                        max={value.max_tiktok_post_invite_count}
                        onChange={({ min, max }) => {
                            onChange({ min_tiktok_post_invite_count: min, max_tiktok_post_invite_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_invite_count: undefined,
                                max_tiktok_post_invite_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_invite_count: undefined,
                                max_tiktok_post_invite_count: undefined,
                            });
                            helpers.close();
                        }}
                    />
                );
            },
        },
        {
            name: 'min_instagram_post_invite_count',
            label: 'Instagram Past Invites',
            icon: MailIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_instagram_post_invite_count}
                        max={value.max_instagram_post_invite_count}
                        onChange={({ min, max }) => {
                            onChange({ min_instagram_post_invite_count: min, max_instagram_post_invite_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_invite_count: undefined,
                                max_instagram_post_invite_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_invite_count: undefined,
                                max_instagram_post_invite_count: undefined,
                            });
                            helpers.close();
                        }}
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_rate',
            label: 'TikTok Post Rate',
            icon: TickIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_tiktok_post_rate}
                        onChange={({ min }) => {
                            onChange({ min_tiktok_post_rate: min });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_rate: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_rate: undefined,
                            });
                            helpers.close();
                        }}
                    >
                        {({ minProps }) => <DebounceInput {...minProps} placeholder="Min %" />}
                    </MinMaxFilter>
                );
            },
        },
        {
            name: 'min_instagram_post_rate',
            label: 'Instagram Post Rate',
            icon: TickIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        min={value.min_instagram_post_rate}
                        onChange={({ min }) => {
                            onChange({ min_instagram_post_rate: min });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_rate: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_rate: undefined,
                            });
                            helpers.close();
                        }}
                    >
                        {({ minProps }) => <DebounceInput {...minProps} placeholder="Min %" />}
                    </MinMaxFilter>
                );
            },
        },
    ];

    return (
        <div className={styles.container}>
            <SearchInput
                value={value.search}
                onChange={(search) => onChange({ search })}
                placeholder="Search"
                className={styles.search}
            />

            <Filters
                sessionKey="mw-campaign-details-ig-creators"
                filters={filters}
                options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
            />
        </div>
    );
};

export default InstagramCreatorsFilters;
