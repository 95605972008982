import { Context } from '@fluentui/react-context-selector';
import { microwave } from '@round/api';
import { DataState, PaginatedApiResponseData, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';

type State = DataState<PaginatedApiResponseData<microwave.PaymentRequest> | null>;

type Actions =
    | ReducerAction<'paymentRequestsIdle'>
    | ReducerAction<'loadPaymentRequests'>
    | ReducerActionWithPayload<'paymentRequestsInitialized', PaginatedApiResponseData<microwave.PaymentRequest>>
    | ReducerActionWithPayload<'errorLoadingPaymentRequests', { message: string }>
    | ReducerAction<'resetPaymentRequests'>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    paymentRequestsIdle: (state) => ({
        ...state,
        error: null,
        status: 'idle',
    }),
    loadPaymentRequests: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    errorLoadingPaymentRequests: (state, { payload: { message } }) => ({
        ...state,
        error: message,
        status: 'error',
    }),
    paymentRequestsInitialized: (state, { payload: data }) => ({
        data,
        error: null,
        status: 'success',
    }),
    resetPaymentRequests: () => initialState,
});

type HookConfig = {
    params: microwave.GetPaymentRequestsParams;
    isImperative?: boolean;
};
export function makePaymentRequestsListDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function usePaymentRequests({ params, isImperative }: HookConfig) {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (params: microwave.GetPaymentRequestsParams, requestInit?: RequestInit) => {
                dispatch({ type: 'loadPaymentRequests' });

                try {
                    const response = await microwave.getPaymentRequests(params, requestInit);
                    if (response.status !== 200) {
                        dispatch({
                            type: 'errorLoadingPaymentRequests',
                            payload: { message: 'Could not load payment requests' },
                        });
                        return response;
                    }

                    dispatch({ type: 'paymentRequestsInitialized', payload: response.data });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        dispatch({ type: 'paymentRequestsIdle' });
                        throw e;
                    }

                    dispatch({
                        type: 'errorLoadingPaymentRequests',
                        payload: { message: 'Could not load payment requests' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        const isInitialized = state.status === 'success' || state.status === 'error';
        useAbortableEffect(
            (signal) => {
                if (isImperative || isInitialized) {
                    return;
                }

                fetchData(
                    {
                        page: params.page || 1,
                        page_size: params.page_size,
                    },
                    { signal }
                ).catch(() => {});
            },
            [params.page, params.page_size, isImperative, isInitialized, fetchData]
        );

        const reset = useCallback(() => dispatch({ type: 'resetPaymentRequests' }), [dispatch]);

        return {
            ...state,
            fetchData,
            reset,
        };
    };
}
