import useCampaigns from '../useCampaigns';
import { useInstagramCampaignStats } from './instagramCampaignStats';
import { useMemo } from 'react';
import { isNumber } from 'utility/utility';
import { TabPanel, Tabs } from '@round/ui-kit';
import { useUrlState } from '@round/utils';
import {
    CampaignStats,
    CampaignTab,
    getCampaignTabStateFromUrlSearchParam,
    setCampaignTabStateInUrlSearchParam,
} from '../../helpers';
import Campaign from '../Campaign/Campaign';
import InstagramCreators from '../../Creators/InstagramCreators/InstagramCreators';
import styles from './InstagramCampaigns.module.css';
import InstagramContacted from '../../Invites/Contacted/InstagramContacted/InstagramContacted';
import InstagramPosted from '../../Invites/Posted/InstagramPosted/InstagramPosted';

type Props = {
    releaseId: number;
};

type UrlState = {
    //id-tab-open
    campaignTabs?: string;
};

const InstagramCampaigns = ({ releaseId }: Props) => {
    const { url: urlData, setSearchParams } = useUrlState<UrlState>();

    const { data: campaigns } = useCampaigns({ releaseId });
    const { stats: instagramCampaignStats } = useInstagramCampaignStats(
        useMemo(
            () =>
                campaigns
                    ?.filter((c) => c.platform === 'instagram')
                    .map((c) => c.instagram_campaign_id)
                    .filter(isNumber) ?? [],
            [campaigns]
        )
    );

    return (
        <>
            {campaigns
                ?.filter((campaign) => campaign.platform === 'instagram')
                .map((campaign) => {
                    const stats = instagramCampaignStats[campaign.instagram_campaign_id];

                    const campaignStats: CampaignStats | undefined = stats?.data
                        ? {
                              amountSpent: stats.data.amount_spent,
                              engagementRate: stats.data.engagement_rate,
                              postCount: stats.data.post_count,
                              totalViews: stats.data.total_views,
                          }
                        : undefined;

                    const [currentTab, isOpen] = getCampaignTabStateFromUrlSearchParam(
                        campaign.id,
                        urlData.searchParams.campaignTabs
                    );

                    const setTabState = (isOpen: boolean, tab?: CampaignTab) =>
                        setSearchParams({
                            campaignTabs: setCampaignTabStateInUrlSearchParam(
                                campaign.id,
                                urlData.searchParams.campaignTabs,
                                isOpen,
                                tab
                            ),
                        });

                    return (
                        <Tabs activeTab={currentTab} onChange={(tab) => setTabState(true, tab)}>
                            <Campaign
                                key={campaign.id}
                                className={styles.campaign}
                                campaign={campaign}
                                stats={campaignStats}
                                isExpanded={isOpen}
                                onExpand={() => setTabState(!isOpen)}
                            >
                                <TabPanel className={styles.panel} name="creators">
                                    <InstagramCreators campaignId={campaign.id} />
                                </TabPanel>
                                <TabPanel className={styles.panel} name="posted">
                                    <InstagramPosted campaignId={campaign.id} />
                                </TabPanel>
                                <TabPanel className={styles.panel} name="contacted">
                                    <InstagramContacted campaignId={campaign.id} />
                                </TabPanel>
                            </Campaign>
                        </Tabs>
                    );
                })}
        </>
    );
};

export default InstagramCampaigns;
