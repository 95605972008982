import { microwave, PaginatedApiResponseData } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = Record<number, DataState<PaginatedApiResponseData<microwave.Invite> | null> | undefined>;

type Actions =
    | ReducerActionWithPayload<'contactedIdle', number>
    | ReducerActionWithPayload<'loadContacted', number>
    | ReducerActionWithPayload<
          'contactedInitialized',
          {
              campaignId: number;
              data: PaginatedApiResponseData<microwave.Invite>;
          }
      >
    | ReducerActionWithPayload<'errorLoadingContacted', { campaignId: number; message: string }>
    | ReducerAction<'resetContacted'>
    | ReducerActionWithPayload<'updateContacted', { campaignId: number; data: microwave.Invite }>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    contactedIdle: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: null,
            status: 'idle',
        },
    }),
    loadContacted: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            status: 'loading',
            error: null,
        },
    }),
    errorLoadingContacted: (state, { payload: { campaignId, message } }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: message,
            status: 'error',
        },
    }),
    contactedInitialized: (state, { payload: { campaignId, data } }) => {
        return {
            ...state,
            [campaignId]: {
                data,
                error: null,
                status: 'success',
            },
        };
    },
    resetContacted: () => initialState,
    updateContacted: (state, { payload: { campaignId, data } }) => {
        const prevData = state[campaignId]?.data;
        if (!prevData) {
            return state;
        }

        return {
            ...state,
            [campaignId]: {
                error: null,
                status: 'success',
                data: {
                    ...prevData,
                    results: prevData.results.map((i) => (i.id === data.id ? data : i)),
                },
            },
        };
    },
});
