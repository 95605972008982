import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import styles from './CampaignName.module.css';

type Props = {
    name: string | null | undefined;
};

const CampaignName = ({ name }: Props) => {
    const campaignName = name || '-';
    return (
        <TruncationPopover content={campaignName}>
            {(setRef) => (
                <p ref={setRef} className={styles.campaignName}>
                    {campaignName}
                </p>
            )}
        </TruncationPopover>
    );
};

export default CampaignName;
