import { useState } from 'react';

type Override<T> = {
    id: T;
    emailAddress: string;
};

export function useEmailAddressOverrides<T extends string | number>(currentId: T | undefined) {
    const [overrides, setOverrides] = useState<Override<T>[]>([]);

    const selectedOverride = overrides.find((override) => override.id === currentId);
    const setSelectedOverride = (emailAddress: string) => {
        if (!currentId) {
            return;
        }

        setOverrides((prevOverrides) => {
            if (!selectedOverride) {
                return [...prevOverrides, { id: currentId, emailAddress }];
            }

            return prevOverrides.map((prevOverride) => {
                if (prevOverride.id === currentId) {
                    return { ...prevOverride, emailAddress };
                }

                return prevOverride;
            });
        });
    };

    return {
        selectedOverride: selectedOverride ? selectedOverride.emailAddress : null,
        setSelectedOverride,
        overrides,
        resetOverrides: () => setOverrides([]),
    };
}
