import { StringSearchParams, UrlData, useSessionUrlState } from '@round/utils';
import useInstagramPosted from './useInstagramPosted';
import InstagramPostedTable from './InstagramPostedTable/InstagramPostedTable';
import { useEffect, useMemo } from 'react';
import { microwave } from '@round/api';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';

type Props = {
    campaignId: number;
};

type UrlState = {
    page: number;
    pageSize: number;
    ordering: Exclude<microwave.GetInvitesParams['ordering'], undefined>;
};

const initialState: UrlState = {
    page: 1,
    pageSize: 10,
    ordering: '-post_create_time',
};

const parseUrlState = ({
    searchParams: { page, pageSize, ordering },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialState.page,
    pageSize: pageSize ? Number(pageSize) : initialState.pageSize,
    ordering: (ordering as UrlState['ordering']) ?? initialState.ordering,
});

const InstagramPosted = ({ campaignId }: Props) => {
    const { url, mergeSearchParams, persistSession } = useSessionUrlState<UrlState>(
        'mw-ig-campaign-posted-params',
        (prevUrlData, sessionData, helpers) => {
            const fallbackState = Object.fromEntries(
                Object.entries({ ...initialState, ...sessionData?.searchParams }).filter(
                    ([key]) => !Object.hasOwn(prevUrlData.searchParams, key)
                )
            );

            return helpers.merge(prevUrlData, { searchParams: fallbackState });
        }
    );

    const { page, pageSize, ordering } = parseUrlState(url);
    const {
        data,
        status,
        error,
        creatorsData,
        audiosData,
        instagramUserImagesData,
        getIsAccountDataLoading,
    } = useInstagramPosted({
        params: { page, page_size: pageSize, campaign_id: campaignId, ordering },
    });

    useEffect(() => {
        return () => {
            persistSession({ searchParams: url.searchParams });
        };
    }, [persistSession, url.searchParams]);

    const rows = useMemo(() => {
        return (data?.results ?? []).map((invite) => {
            const creator = creatorsData[invite.influencer_id]?.data ?? null;
            return {
                ...invite,
                creator,
                audio: invite.instagram_audio_id ? audiosData[invite.instagram_audio_id]?.data ?? null : null,
                image: creator?.instagram_user_id
                    ? instagramUserImagesData[creator.instagram_user_id]?.data ?? null
                    : null,
            };
        });
    }, [audiosData, creatorsData, data?.results, instagramUserImagesData]);

    return (
        <InstagramPostedTable
            data={rows}
            page={page}
            setPage={(page) => mergeSearchParams({ page })}
            pageSize={pageSize}
            setPageSize={(pageSize) => mergeSearchParams({ pageSize })}
            count={data?.count ?? 0}
            noDataLabel={status === 'error' ? error : 'No data'}
            meta={{ isLoading: status === 'loading' || status === 'idle', getIsAccountDataLoading }}
            sorting={mapOrderingToTableSorting([ordering])}
            onSortingChange={(sorting) => mergeSearchParams({ ordering: mapTableSortingToOrdering(sorting) })}
        />
    );
};

export default InstagramPosted;
