import { microwave } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as HashIcon } from 'assets/icons/Hash.svg';
import TextFilter from 'ui-new/Filters/TextFilter/TextFilter';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import styles from './InstagramContactedFilters.module.css';

type FilterParams = Pick<microwave.GetInvitesParams, 'chase_email_count'>;

type Props = DomainFiltersProps<FilterParams>;

const InstagramContactedFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof FilterParams>[] = [
        {
            name: 'chase_email_count',
            label: '# of chase emails',
            icon: () => <HashIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <TextFilter
                        type="number"
                        name={this.label}
                        icon={<Icon />}
                        value={value.chase_email_count?.toString() ?? ''}
                        onChange={(val) => onChange({ chase_email_count: val ? Number(val) : undefined })}
                        onClear={() => {
                            onChange({ chase_email_count: undefined });
                            helpers.remove();
                        }}
                        placeholder="Enter # of chase emails"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="mw-ig-contacted-tab-filters"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
        />
    );
};

export default InstagramContactedFilters;
