import React, { useContext, useState } from 'react';
import { fetchWithToken, showNotification } from '../../../../../helpers';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import styles from './BadgeList.module.css';
import useUrlState from '../../../../../Hooks/useUrlState';
import Audios from './Audios/Audios';
import Modal, { ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import Button from '../../../../../ui/Buttons/Button/Button';
import { TiktokInfluencerPlanAudio, TiktokInfluencerPlanHashtag } from '../../../../../App.types';
import AddAudioModal from './AddAudioModal/AddAudioModal';
import Hashtags from './Hashtags/Hashtags';
import AddHashtagModal from './AddHashtagModal/AddHashtagModal';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import useCreatorPlanTiktokAudios from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import useCreatorPlanTiktokHashtags from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokHashtags';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { useFeatureFlags } from '../../../../../utility/featureFlags/FeatureFlagsContext';

export const TiktokBadgeList = () => {
    const isGroupWithAudioEnabled = useFeatureFlags().isEnabled('influencer_post_group_with_audio');

    const [addHashtagModalOpen, setAddHashtagModalOpen] = useState(false);
    const [hashtagToDelete, setHashtagToDelete] = useState<TiktokInfluencerPlanHashtag>();
    const [addAudioModalOpen, setAddAudioModalOpen] = useState(false);
    const [audioToDelete, setAudioToDelete] = useState<TiktokInfluencerPlanAudio>();
    const [, setUrlState] = useUrlState<{ audioId: number }>();
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const context = useContext(InfluencerPlanContext);
    const { influencerPlan } = context;
    const { addTiktokHashtag, removeTiktokHashtag, addTiktokAudio, removeTiktokAudio } = useNonNullContext(
        TiktokCreatorsContext
    );

    const {
        init: initTiktokHashtags,
        hashtags: tiktokHashtags,
        isLoading: isTiktokHashtagsLoading,
        isInitialized: isTiktokHashtagsInitialized,
    } = useCreatorPlanTiktokHashtags();

    const {
        influencerPlanAudios: tiktokAudios,
        loading: tiktokAudiosLoading,
        error: tiktokAudiosError,
        initialized: tiktokAudiosInitialized,
    } = useCreatorPlanTiktokAudios();

    useAbortableEffect(
        (signal) => {
            if (!isTiktokHashtagsInitialized) {
                initTiktokHashtags({ signal });
            }
        },
        [initTiktokHashtags, isTiktokHashtagsInitialized]
    );

    const setCurrentTiktokAudio = (audioId: number | undefined) => setUrlState({ audioId });

    const deleteItem = (type: 'hashtag' | 'audio', id: number) => {
        fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-plan-${type}/${id}/`, {
            method: 'DELETE',
        })
            .then((_) => {
                if (type === 'hashtag') {
                    removeTiktokHashtag(id);
                    return;
                }

                removeTiktokAudio(id);
            })
            .catch((err) => alert(err));
    };

    const deleteHashtag = async () => {
        if (typeof hashtagToDelete?.id !== 'number') {
            return;
        }

        try {
            await deleteItem('hashtag', hashtagToDelete.id);
            setHashtagToDelete(undefined);
            showNotification('Hashtag deleted', 'info');
        } catch {
            showNotification('Could not delete hashtag', 'error');
        }
    };

    const deleteAudio = async () => {
        if (typeof audioToDelete?.id !== 'number') {
            return;
        }

        try {
            await deleteItem('audio', audioToDelete.id);
            setAudioToDelete(undefined);
            showNotification('Audio deleted', 'info');
        } catch {
            showNotification('Could not delete audio', 'error');
        }
    };

    return (
        <>
            <div className={styles.badgeContainer}>
                <Hashtags
                    disabled={!canEdit && !tiktokHashtags.length}
                    loading={!isTiktokHashtagsInitialized || isTiktokHashtagsLoading}
                    hashtags={tiktokHashtags}
                    readonly={!canEdit}
                    onAddHashtag={() => setAddHashtagModalOpen(true)}
                    onHashtagDelete={(hashtag) => setHashtagToDelete(hashtag)}
                />

                {influencerPlan && (
                    <AddHashtagModal
                        influencerPlanId={influencerPlan.id}
                        isModalOpen={addHashtagModalOpen}
                        closeModal={() => setAddHashtagModalOpen(false)}
                        onHashtagCreated={addTiktokHashtag}
                    />
                )}

                <Modal
                    closeOnOverlayClick
                    className={styles.textAlignCenter}
                    isOpen={!!hashtagToDelete}
                    onClose={() => setHashtagToDelete(undefined)}
                >
                    <ModalTitle>Delete #{hashtagToDelete?.hashtag}?</ModalTitle>
                    <ModalFooter align="center">
                        <Button onClick={() => setHashtagToDelete(undefined)}>Cancel</Button>
                        <Button type="filled" color="black" onClick={deleteHashtag}>
                            Delete
                        </Button>
                    </ModalFooter>
                </Modal>
                <Audios
                    disabled={!canEdit && !tiktokAudios.length}
                    loading={tiktokAudiosLoading || (!tiktokAudiosError && !tiktokAudiosInitialized)}
                    readonly={!canEdit || isGroupWithAudioEnabled}
                    audios={tiktokAudios}
                    onAddAudio={() => setAddAudioModalOpen(true)}
                    onAudioClick={(audio) => setCurrentTiktokAudio(Number(audio.audio_id))}
                    onAudioDelete={setAudioToDelete}
                />

                {influencerPlan && (
                    <AddAudioModal
                        influencerPlanId={influencerPlan.id}
                        isModalOpen={addAudioModalOpen}
                        closeModal={() => setAddAudioModalOpen(false)}
                        onAudioCreated={addTiktokAudio}
                    />
                )}

                <Modal
                    className={styles.textAlignCenter}
                    closeOnOverlayClick
                    isOpen={!!audioToDelete}
                    onClose={() => setAudioToDelete(undefined)}
                >
                    <ModalTitle>Delete "{audioToDelete?.title}" audio?</ModalTitle>
                    <ModalFooter align="center">
                        <Button onClick={() => setAudioToDelete(undefined)}>Cancel</Button>
                        <Button type="filled" color="black" onClick={deleteAudio}>
                            Delete
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
};
