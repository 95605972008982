import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { Campaign, CampaignStats } from './campaigns.types';

export type GetCampaignsParams = Partial<
    PaginatedRequest & {
        release_id: string;
        influencer_campaign_id: string;
    }
>;

export type GetCampaignsResponse = ApiResponse<PaginatedApiResponseData<Campaign>, 200> | NotFoundResponse;

export async function getCampaigns(
    params: GetCampaignsParams,
    requestInit?: RequestInit
): Promise<GetCampaignsResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get microwave campaigns');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type CampaignApiBody = Pick<
    Campaign,
    | 'budget'
    | 'currency_id'
    | 'planned_start_date'
    | 'planned_end_date'
    | 'platform'
    | 'team_members'
    | 'target_post_count'
> & {
    release_id: number;
    instagram_audio_id?: number;
    tiktok_audio_id?: number;
};

type PostCampaignResponse =
    | ApiResponse<Campaign, 201>
    | ApiResponse<ApiResponseError<CampaignApiBody>, 400>
    | ApiResponse<string[], 400>;

export async function postCampaign(data: CampaignApiBody): Promise<PostCampaignResponse> {
    const response = await fetchWithToken('/api/microwave/campaigns/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create microwave campaign');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchCampaignData = Partial<
    Pick<
        Campaign,
        | 'budget'
        | 'currency_id'
        | 'planned_end_date'
        | 'planned_start_date'
        | 'target_post_count'
        | 'team_members'
        | 'brief'
        | 'payment_request_deadline_in_days'
    >
>;

type PatchCampaignResponse =
    | ApiResponse<Campaign, 200>
    | ApiResponse<ApiResponseError<PatchCampaignData>, 400>
    | ApiResponse<string[], 400>
    | NotFoundResponse;

export async function patchCampaign(id: number, data: PatchCampaignData): Promise<PatchCampaignResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update microwave campaign');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetCampaignStatsResponse =
    | ApiResponse<CampaignStats, 200>
    | NotFoundResponse
    // TODO: remove when all platforms are supported
    // Gives error if campaign is not youtube campaign
    | ApiResponse<{ detail: string }, 403>;

export async function getCampaignStats(
    campaignId: number,
    requestInit?: RequestInit
): Promise<GetCampaignStatsResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${campaignId}/stats/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get campaign stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostCampaignInvitesDataItem = {
    influencer_id: number;
    email_template_id: number;
    email_address?: string;
};

export type PostCampaignInvitesResponseData = {
    success: number[];
    failure: {
        [id: number]: ApiResponseError<PostCampaignInvitesDataItem> | string[];
    }[];
};

type PostCampaignInvitesResponse =
    | ApiResponse<PostCampaignInvitesResponseData, 201>
    | ApiResponse<PostCampaignInvitesResponseData, 400>
    | ApiResponse<ApiResponseError<PostCampaignInvitesDataItem>, 400>
    | ApiResponse<string[], 400>;
export async function postCampaignInvites(
    campaignId: number,
    data: PostCampaignInvitesDataItem[],
    signal?: AbortSignal
): Promise<PostCampaignInvitesResponse> {
    const response = await call(`/api/microwave/campaigns/${campaignId}/invites/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not send invites');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
