import { DataState } from '@round/utils';
import { microwave } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<microwave.Campaign[]>;
type Actions =
    | ReducerAction<'loadCampaigns'>
    | ReducerActionWithPayload<'campaignsInitialized', microwave.Campaign[]>
    | ReducerActionWithPayload<'campaignsLoadingError', string>
    | ReducerAction<'resetCampaigns'>
    | ReducerActionWithPayload<'updateCampaign', microwave.Campaign>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadCampaigns: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    campaignsInitialized: (state, { payload }) => ({
        ...state,
        status: 'success',
        error: null,
        data: payload,
    }),
    campaignsLoadingError: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    resetCampaigns: () => initialState,
    updateCampaign: (state, { payload: campaign }) => ({
        ...state,
        data: state.data?.map((c) => (c.id === campaign.id ? campaign : c)) ?? [],
    }),
});
