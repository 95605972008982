import { contact } from '@round/api';
import { useEffect, useMemo, useState } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { GenericDropdownOption } from 'App.types';
import { ValueType } from 'react-select';

type State = {
    status: 'not-initialized' | 'initialized' | 'error';
    templates: contact.EmailTemplate[];
    error: string | null;
};

export default function useEmailTemplatesSelect(notificationType: contact.NotificationType) {
    const [templatesState, setTemplatesState] = useState<State>({
        status: 'not-initialized',
        templates: [],
        error: null,
    });

    useAbortableEffect(
        (signal) => {
            if (templatesState.status === 'not-initialized') {
                contact
                    .getAllEmailTemplates({ notification_type: notificationType, is_active: true }, { signal })
                    .then((templates) => {
                        setTemplatesState({ status: 'initialized', error: null, templates });
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        setTemplatesState({ status: 'error', templates: [], error: 'Could not fetch email templates' });
                    });
            }
        },
        [templatesState.status, notificationType]
    );

    const templateOptions: GenericDropdownOption<number>[] = useMemo(
        () => templatesState.templates.map((t) => ({ value: t.id, label: t.title })).sort((a, b) => a.value - b.value),
        [templatesState.templates]
    );

    const [selectedTemplate, setSelectedTemplate] = useState<ValueType<GenericDropdownOption<number>, false>>(null);
    useEffect(() => {
        if (templatesState.status === 'initialized' && templateOptions.length && !selectedTemplate) {
            setSelectedTemplate(templateOptions[0]);
        }
    }, [selectedTemplate, templateOptions, templatesState.status]);

    return {
        templateOptions,
        selectedTemplate,
        setSelectedTemplate,
        status: templatesState.status,
        error: templatesState.error,
    };
}
