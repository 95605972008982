import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { HeaderContext } from '@tanstack/react-table';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = HeaderContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableEngagementsFooterCell = <T extends Row>({ table }: Context<T>) => {
    const { isLoading, areCampaignStatsLoading, campaignStats } = getTableMeta(table);
    if (isLoading || areCampaignStatsLoading) {
        return <Skeleton />;
    }

    if (typeof campaignStats?.engagement_count !== 'number') {
        return '-';
    }

    return formatPostStatsCellValue(campaignStats.engagement_count);
};

export default PostsTableEngagementsFooterCell;
