import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { PaymentMethod, PaymentStatus } from '../microwave.types';
import { PaymentRequest, PaymentRequestStatus, PaymentRequestTotals } from './paymentRequests.types';

export type GetPaymentRequestsParams = Partial<
    PaginatedRequest & {
        influencer_id: number;
        invite_id: string;
        payment_deadline_start: string;
        payment_deadline_end: string;
        payment_status: PaymentStatus;
        platform: string;
        preferred_payment_method: PaymentMethod;
        status: PaymentRequestStatus;
        ordering: string;
        include_totals: boolean;
    }
>;

type GetPaymentRequestsResponse =
    | ApiResponse<PaginatedApiResponseData<PaymentRequest> & { totals?: PaymentRequestTotals }, 200>
    | NotFoundResponse;

export async function getPaymentRequests(
    params: GetPaymentRequestsParams,
    requestInit?: RequestInit
): Promise<GetPaymentRequestsResponse> {
    const response = await fetchWithToken(
        `/api/microwave/payment-requests/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to get payment requests');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchPaymentRequestBody = Partial<
    Pick<
        PaymentRequest,
        | 'cost'
        | 'currency_id'
        | 'payment_reference'
        | 'venmo_email_address'
        | 'payment_details'
        | 'paypal_email_address'
        | 'status'
    >
>;
type PatchPaymentRequestResponse =
    | ApiResponse<PaymentRequest, 200>
    | ApiResponse<ApiResponseError<PaymentRequest>, 400>
    | NotFoundResponse;

export async function patchPaymentRequest(
    id: number,
    data: PatchPaymentRequestBody
): Promise<PatchPaymentRequestResponse> {
    const response = await fetchWithToken(`/api/microwave/payment-requests/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to update payment request');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type MakePaymentResponse = ApiResponse<null, 204> | ApiResponse<string[], 400> | NotFoundResponse;

export async function makePaypalPayment(requestId: number): Promise<MakePaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/payment-requests/${requestId}/paypal-payments/`, {
        method: 'POST',
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to make PayPal payment');
    }

    return {
        status: 204,
        data: null,
    };
}

export async function makeBankTransfer(requestId: number): Promise<MakePaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/payment-requests/${requestId}/bank-transfer-payments/`, {
        method: 'POST',
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to make Bank Transfer payment');
    }

    return {
        status: 204,
        data: null,
    };
}

export async function makeVenmoPayment(requestId: number): Promise<MakePaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/payment-requests/${requestId}/venmo-payments/`, {
        method: 'POST',
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to make Venmo payment');
    }

    return {
        status: 204,
        data: null,
    };
}
