import { Primitives } from './types';

export function parseUrlSearchParams(searchParams: string) {
    const params = new URLSearchParams(searchParams);
    return Array.from(params.entries())
        .filter((entry) => !!entry[1])
        .reduce<Record<string, string>>((acc, value) => {
            acc[value[0]] = value[1];
            return acc;
        }, {});
}

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
export function encodeQueryString(params: Record<string, string | number | boolean>) {
    const keys = Object.keys(params);
    return keys.length
        ? '?' + keys.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&')
        : '';
}

/**
 * Encode an object as url query string parameters. Also strips undefined/null
 */
export function encodeUrlSearchParams(params: Record<string, Primitives | Primitives[]>) {
    // type guard stripping empty string, null or undefined
    const notEmptyValue = (param: Primitives): param is string | number | boolean => {
        return (
            (typeof param === 'string' && param.trim() !== '') ||
            (param !== undefined && param !== null && typeof param !== 'string')
        );
    };

    const keys = Object.keys(params);
    const strippedParams = keys.reduce<Record<string, string | number | boolean>>((strippedParams, key) => {
        const param = params[key];
        const value = Array.isArray(param) ? param.filter(notEmptyValue).join(',') : param;

        if (notEmptyValue(value)) {
            strippedParams[key] = value;
        }

        return strippedParams;
    }, {});

    return encodeQueryString(strippedParams);
}
