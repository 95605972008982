import { combineReducers } from 'helpers';
import { reducer as releaseReducer, initialState as releaseInitialState } from './Release/reducer';
import { reducer as campaignsReducer, initialState as campaignsInitialState } from './Campaigns/reducer';
import {
    reducer as instagramCampaignStatsReducer,
    initialState as instagramCampaignStatsInitialState,
} from './Campaigns/InstagramCampaigns/instagramCampaignStats';
import { reducer as creatorsReducer, initialState as creatorsInitialState } from './Creators/reducer';
import {
    reducer as instagramUserImagesReducer,
    initialState as instagramUserImagesInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import {
    reducer as microwaveContentTagsReducer,
    initialState as microwaveContentTagsInitialState,
} from 'Modules/Tags/dataHooks/useMicrowaveContentTagsDataHook';
import { reducer as contactedReducer, initialState as contactedInitialState } from './Invites/Contacted/reducer';
import { reducer as postedReducer, initialState as postedInitialState } from './Invites/Posted/reducer';
import {
    reducer as supplementaryCreatorsReducer,
    initialState as supplementaryCreatorsInitialState,
} from 'Modules/Advertising/Microwave/hooks/dataHooks/useCreatorsByIdDataHook';
import {
    reducer as instagramAudiosByIdsReducer,
    initialState as instagramAudiosByIdsInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

export const initialState: State = {
    release: releaseInitialState,
    campaigns: campaignsInitialState,
    creators: creatorsInitialState,
    contacted: contactedInitialState,
    contentTags: microwaveContentTagsInitialState,
    posted: postedInitialState,
    supplementaryCreators: supplementaryCreatorsInitialState,
    instagram: {
        campaignStats: instagramCampaignStatsInitialState,
        userImages: instagramUserImagesInitialState,
        audios: instagramAudiosByIdsInitialState,
    },
};

export const reducer = combineReducers({
    release: releaseReducer,
    campaigns: campaignsReducer,
    creators: creatorsReducer,
    contacted: contactedReducer,
    contentTags: microwaveContentTagsReducer,
    posted: postedReducer,
    supplementaryCreators: supplementaryCreatorsReducer,
    instagram: combineReducers({
        campaignStats: instagramCampaignStatsReducer,
        userImages: instagramUserImagesReducer,
        audios: instagramAudiosByIdsReducer,
    }),
});
