import InstagramCreatorsFilters, { getFilterValues } from './Filters/InstagramCreatorsFilters';
import InstagramCreatorsTable, { InstagramCreatorsTableRow } from './InstagramCreatorsTable/InstagramCreatorsTable';
import useInstagramCreators from './useInstagramCreators';
import { useEffect, useMemo, useState } from 'react';
import { microwave } from '@round/api';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import useMicrowaveContentTags from '../useMicrowaveContentTags';
import { toDecimalPoint, useSessionUrlState } from '@round/utils';
import { RowSelectionState } from '@tanstack/react-table';
import uniq from 'lodash/uniq';
import styles from './InstagramCreators.module.css';
import InitialEmailPreviewModal from '../../EmailPreview/InitialEmailPreviewModal';
import EmailPreviewButton from '../../components/EmailPreviewButton/EmailPreviewButton';

type Props = {
    campaignId: number;
};

type UrlState = Omit<Partial<microwave.GetMicrowaveInfluencersParams>, 'ordering'> & {
    selected?: string;
};

const initialUrlState: Pick<Required<UrlState>, 'page' | 'page_size'> = {
    page: 1,
    page_size: 10,
};

const InstagramCreators = ({ campaignId }: Props) => {
    // would probably be replaced with a solution that includes filters as well
    const {
        url: { searchParams: urlState },
        mergeSearchParams,
        persistSession,
    } = useSessionUrlState<UrlState>('mw-ig-campaigns-creators-params', (url, session, { merge }) => {
        const fallbackState = Object.fromEntries(
            Object.entries({ ...initialUrlState, ...session?.searchParams }).filter(
                ([key]) => !Object.hasOwn(url.searchParams, key)
            )
        );
        return merge(url, { searchParams: { ...fallbackState } });
    });

    useEffect(() => {
        return () => persistSession({ searchParams: urlState });
    }, [persistSession, urlState]);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    const { data, status, error, reset, userImages, getIsAccountDataLoading, updateCreator } = useInstagramCreators(
        campaignId,
        {
            page: page,
            page_size: pageSize,
            search: urlState.search,
            content_tags: JSON.stringify(
                mapConditionsToApi(parseConditionsFromUrlString(urlState.content_tags).filter(isIncludeCondition))
            ),
            exclude_content_tags: JSON.stringify(
                mapExcludeConditionsToApi(
                    parseConditionsFromUrlString(urlState.content_tags).filter(isExcludeCondition)
                )
            ),
            hashtag_ids: urlState.hashtag_ids,
            rating: urlState.rating,
            min_tiktok_post_count: urlState.min_tiktok_post_count ? Number(urlState.min_tiktok_post_count) : undefined,
            max_tiktok_post_count: urlState.max_tiktok_post_count ? Number(urlState.max_tiktok_post_count) : undefined,
            min_instagram_post_count: urlState.min_instagram_post_count
                ? Number(urlState.min_instagram_post_count)
                : undefined,
            max_instagram_post_count: urlState.max_instagram_post_count
                ? Number(urlState.max_instagram_post_count)
                : undefined,
            min_tiktok_post_invite_count: urlState.min_tiktok_post_invite_count
                ? Number(urlState.min_tiktok_post_invite_count)
                : undefined,
            max_tiktok_post_invite_count: urlState.max_tiktok_post_invite_count
                ? Number(urlState.max_tiktok_post_invite_count)
                : undefined,
            min_instagram_post_invite_count: urlState.min_instagram_post_invite_count
                ? Number(urlState.min_instagram_post_invite_count)
                : undefined,
            max_instagram_post_invite_count: urlState.max_instagram_post_invite_count
                ? Number(urlState.max_instagram_post_invite_count)
                : undefined,
            min_tiktok_post_rate: urlState.min_tiktok_post_rate
                ? toDecimalPoint(Number(urlState.min_tiktok_post_rate) / 100, 2)
                : undefined,
            min_instagram_post_rate: urlState.min_instagram_post_rate
                ? toDecimalPoint(Number(urlState.min_instagram_post_rate) / 100, 2)
                : undefined,
        }
    );

    const { data: contentTags } = useMicrowaveContentTags();

    const rows: InstagramCreatorsTableRow[] = useMemo(
        () =>
            data?.results.map((creator) => {
                const image = creator.instagram_user_id ? userImages[creator.instagram_user_id]?.data ?? null : null;
                const creatorTags = contentTags?.filter((tag) => creator.content_tags.includes(tag.id)) ?? [];

                return {
                    ...creator,
                    instagramUserImage: image,
                    contentTagsOptions: creatorTags.map((tag) => ({ value: tag.id, label: tag.name })),
                };
            }) ?? [],
        [contentTags, data?.results, userImages]
    );

    const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState(false);

    const selectedCount = urlState.selected?.split(',').length ?? 0;
    const isEmailPreviewDisabled = selectedCount === 0;

    return (
        <>
            <menu className={styles.container}>
                <InstagramCreatorsFilters
                    value={getFilterValues(urlState)}
                    onChange={(filterVals, shouldRefreshData = true) => {
                        if (shouldRefreshData === false) {
                            mergeSearchParams({ ...filterVals });
                            return;
                        }
                        mergeSearchParams({ ...filterVals, page: 1 });
                        reset();
                    }}
                />

                <EmailPreviewButton
                    onClick={() => setIsEmailPreviewModalOpen(true)}
                    disabled={isEmailPreviewDisabled}
                    hint={isEmailPreviewDisabled ? 'Select users to preview email' : undefined}
                />
            </menu>

            <InstagramCreatorsTable
                data={rows}
                count={data?.count ?? 0}
                page={page}
                setPage={(page) => mergeSearchParams({ page })}
                pageSize={pageSize}
                setPageSize={(pageSize) => mergeSearchParams({ page_size: pageSize })}
                noDataLabel={!data?.results.length && (status === 'error' ? error : 'No data')}
                rowSelection={
                    urlState.selected?.split(',').reduce((acc, id) => {
                        acc[id] = true;
                        return acc;
                    }, {} as RowSelectionState) ?? {}
                }
                getRowId={(row, index) => (row.id ? `${row.id}` : `skeleton-${index}`)}
                onRowSelectionChange={(selection) => {
                    const incomingIds = rows.filter((row) => selection[`${row.id}`]).map((row) => row.id.toString());

                    mergeSearchParams((prev) => ({
                        selected: uniq([
                            ...(prev.selected?.split(',').filter((id) => selection[id]) ?? []),
                            ...incomingIds,
                        ]).join(','),
                    }));
                }}
                meta={{
                    isLoading: status === 'loading',
                    getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.instagram_user_id),
                    updateCreator,
                }}
            />

            <InitialEmailPreviewModal
                isOpen={isEmailPreviewModalOpen}
                onClose={() => setIsEmailPreviewModalOpen(false)}
                campaignId={campaignId}
                influencerIds={urlState.selected?.split(',').map(Number) ?? []}
            />
        </>
    );
};

export default InstagramCreators;
