import React, { useMemo, useState } from 'react';
import { microwave, OrderingValues } from '@round/api';
import useUrlState from '../../../../../../Hooks/useUrlState';
import useCreators from './useCreators';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import CreatorsTable, { CreatorsTableRow } from './CreatorsTable/CreatorsTable';
import styles from './Creators.module.css';
import cn from 'classnames';
import {
    Button,
    Card,
    Checkbox,
    HierarchicalOptionLabel,
    Modal,
    Popover,
    Select,
    Skeleton,
    conditionTypeOptionsWithExclude,
} from '@round/ui-kit';
import { ReactComponent as CloseIcon } from '../../../../../../assets/Close.svg';
import { ReactComponent as GlobeIcon } from '../../../../../../assets/Globe.svg';
import { ReactComponent as MusicIcon } from '../../../../../../assets/MusicNote.svg';
import InitialEmailPreview, { PreviewUser } from '../../EmailPreview/InitialEmailPreview/InitialEmailPreview';
import { DebounceInput } from 'react-debounce-input';
import { StylesConfig, ValueType } from 'react-select';
import CategoriesFilter from '../../../../../Tags/components/Filters/CategoriesFilter/CategoriesFilter';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
    stringifyConditionsToUrlString,
} from '../../../../../Tags/components/Filters/helpers';
import isEqual from 'lodash/isEqual';
import { useAudioSelect } from '../../../../../TikTok/hooks/useAudioSelect';
import {
    AudioOption,
    AudioSelectOption,
} from '../../../../../TikTok/components/AudioSelectComponents/AudioSelectComponents';
import { AudioPlayerProvider } from '../../../../../AudioPlayer/AudioPlayerContext';
import { GenericDropdownOption } from '../../../../../../App.types';
import {
    convertOrderByToOrdering,
    convertOrderingToOrderBy,
} from '../../../../../../ui/PaginationTable/PaginationTable.helpers';
import { getNumbersArrayFromString, isOrderingParam } from '../../../../../../utility/utility';
import { useMicrowaveContentTags } from '../../contexts/MicrowaveContentTags';
import GroupedOptionMultiSelect from '../../../../../../ui/DataEntry/Select/GroupedSelectionsMultiSelect/GroupedSelectionsMultiSelect';
import useHashtagsSelect from './useHashtagsSelect';
import useComponentWillUnmount from '../../../../../../Hooks/useComponentWillUnmount';
import { useInfluencerLocationSelect } from '../../../../../../Hooks/selectOptions/useInfluencerLocationSelect';
import RatingSelect from '../../../../../../ui/RatingSelect/RatingSelect';
import { useGenres } from 'Hooks/useGenres';
import { showNotification } from 'helpers';
import Dropdown from 'ui/Dropdown/Dropdown';
import { ReactComponent as TiktokIcon } from 'assets/icons/platform/TikTok.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/Youtube.svg';

type UrlState = Omit<microwave.GetMicrowaveInfluencersParams, 'exclude_invited_to_plan' | 'ordering'> & {
    should_exclude_invited_to_plan: string;
    should_exclude_plan_audio: string;
    ordering?: OrderingValues<microwave.MicrowaveInfluencersSortingKey>;
};

type Props = {
    audioId: number;
    planId: number;
};

const initialUrlState: Pick<
    Required<UrlState>,
    'page' | 'page_size' | 'should_exclude_invited_to_plan' | 'should_exclude_plan_audio'
> = {
    page: 1,
    page_size: 10,
    should_exclude_invited_to_plan: 'true',
    should_exclude_plan_audio: 'true',
};

const DEBOUNCE_TIMEOUT = 700;

const selectStyles: StylesConfig = {
    container: (base) => ({
        ...base,
        width: '12.5rem',
        flex: 1,
    }),
    control: (base) => ({
        ...base,
        height: '100%',
        borderRadius: '0.375rem',
    }),
    menu: (base) => ({
        ...base,
        minWidth: 'max-content',
        overflow: 'hidden',
    }),
    menuList: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
};

const Creators = ({ audioId, planId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    useComponentWillUnmount(() => {
        sessionStorage.setItem('micro_creators_url_state', JSON.stringify(urlState));
    });

    const [locationFilterRef, setLocationFilterRef] = useState<HTMLDivElement | null>(null);

    const [selectedCreators, setSelectedCreators] = useState<CreatorsTableRow[]>([]);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewCreators, setPreviewCreators] = useState<CreatorsTableRow[]>([]);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const page_size = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const isExcludeInvitedFilterEnabled = urlState.should_exclude_invited_to_plan === 'true';
    const isExcludePlanAudioFilterEnabled = urlState.should_exclude_plan_audio === 'true';
    const isHasInstagramAccountFilterEnabled = urlState.has_instagram_account === 'true';
    const isHasTiktokAccountFilterEnabled = urlState.has_tiktok_account === 'true';
    const isHasYoutubeAccountFilterEnabled = urlState.has_youtube_account === 'true';
    const ordering =
        urlState.ordering && isOrderingParam(urlState.ordering, microwave.isMicrowaveInfluencersSortingKey)
            ? urlState.ordering
            : undefined;

    const { tags: contentTags, loading: isContentTagsLoading, init: initContentTags } = useMicrowaveContentTags();

    useAbortableEffect(
        (signal) => {
            initContentTags({ signal });
        },
        [initContentTags]
    );

    const { value: postedAudios, options: postedWithAudiosOptions, ...postedWithAudioSelectProps } = useAudioSelect({
        initialAudioIdData: useMemo(() => getNumbersArrayFromString(urlState.posted_with_audio ?? ''), [
            urlState.posted_with_audio,
        ]),
        isMulti: true,
        searchOnly: true,
    });

    const {
        value: excludePostedAudios,
        options: excludeWithAudiosOptions,
        ...excludePostedWithAudioSelectProps
    } = useAudioSelect({
        initialAudioIdData: useMemo(() => getNumbersArrayFromString(urlState.exclude_posted_with_audio ?? ''), [
            urlState.exclude_posted_with_audio,
        ]),
        isMulti: true,
    });

    const getExcludePostedAudiosValue = () => {
        const excludeAudios = (urlState.exclude_posted_with_audio?.split(',') ?? []).map((a) => Number(a));

        if (isExcludePlanAudioFilterEnabled) {
            return excludeAudios.concat(audioId);
        }

        return excludeAudios;
    };

    const { selectedHashtags, setSelectedHashtags, hashtagSelectProps } = useHashtagsSelect(
        useMemo(() => getNumbersArrayFromString(urlState.hashtag_ids ?? ''), [urlState.hashtag_ids])
    );

    const { selectProps: countrySelectProps } = useInfluencerLocationSelect({
        type: 'country',
        ordering: 'country_name',
    });

    const { selectProps: citySelectProps, reset: resetCitySelect } = useInfluencerLocationSelect({
        type: 'city',
        ordering: 'city',
        country_code: countrySelectProps.value?.countryCode,
    });

    const doLocationSelectsHaveValue = !!countrySelectProps.value || !!citySelectProps.value;
    const locationSelectValue = citySelectProps.value?.value ?? countrySelectProps.value?.value;

    const {
        genres: genreOptions,
        init: initGenres,
        isInitialized: isGenresInitialized,
        isLoading: isGenresLoading,
    } = useGenres();

    const selectedGenreOptions = genreOptions.filter((option) =>
        (urlState.with_genre_affinity?.split(',') ?? []).includes(option.value)
    );

    useAbortableEffect(
        (signal) => {
            if (!isGenresInitialized) {
                initGenres({ signal }).catch(() => showNotification('Could not fetch genre options', 'error'));
            }
        },
        [initGenres, isGenresInitialized]
    );

    const { creators, creatorsCount, error, isInitialized, isLoading, init, updateCreator, reset } = useCreators({
        page,
        page_size,
        exclude_posted_with_audio: getExcludePostedAudiosValue().toString(),
        exclude_invited_to_plan: isExcludeInvitedFilterEnabled ? planId : undefined,
        rating: urlState.rating,
        min_tiktok_post_count: urlState.min_tiktok_post_count ? Number(urlState.min_tiktok_post_count) : undefined,
        max_tiktok_post_count: urlState.max_tiktok_post_count ? Number(urlState.max_tiktok_post_count) : undefined,
        min_tiktok_post_invite_count: urlState.min_tiktok_post_invite_count
            ? Number(urlState.min_tiktok_post_invite_count)
            : undefined,
        max_tiktok_post_invite_count: urlState.max_tiktok_post_invite_count
            ? Number(urlState.max_tiktok_post_invite_count)
            : undefined,
        search: urlState.search,
        location_id: locationSelectValue ? String(locationSelectValue) : undefined,
        content_tags: JSON.stringify(
            mapConditionsToApi(parseConditionsFromUrlString(urlState.content_tags).filter(isIncludeCondition))
        ),
        exclude_content_tags: JSON.stringify(
            mapExcludeConditionsToApi(parseConditionsFromUrlString(urlState.content_tags).filter(isExcludeCondition))
        ),
        posted_with_audio: postedAudios?.map((o) => o.value).join(),
        hashtag_ids: urlState.hashtag_ids,
        with_genre_affinity: urlState.with_genre_affinity,
        min_relevant_post_count: urlState.min_relevant_post_count
            ? Number(urlState.min_relevant_post_count)
            : undefined,
        min_tiktok_post_rate: urlState.min_tiktok_post_rate ? Number(urlState.min_tiktok_post_rate) / 100 : undefined,
        has_instagram_account: isHasInstagramAccountFilterEnabled || undefined,
        has_tiktok_account: isHasTiktokAccountFilterEnabled || undefined,
        has_youtube_account: isHasYoutubeAccountFilterEnabled || undefined,
        ordering: urlState.with_genre_affinity
            ? [ordering, '-genre_affinity'].filter(
                  (v): v is OrderingValues<microwave.MicrowaveInfluencersSortingKey> => !!v
              )
            : ordering,
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [init, isInitialized]
    );

    const [rowsDisplayed, setRowsDisplayed] = useState(0);
    const total = creatorsCount <= page_size ? rowsDisplayed : creatorsCount - (page_size - rowsDisplayed);
    const rowsDisplayedText = `displaying ${rowsDisplayed} out of ${total}`;
    const isTableLoading = isLoading || (!error && !isInitialized);

    return (
        <div className={styles.container}>
            <div className={styles.filtersRow}>
                <div className={styles.filters}>
                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Posted on TikTok audio</span>
                        <AudioPlayerProvider>
                            <GroupedOptionMultiSelect
                                {...postedWithAudioSelectProps}
                                filterOption={null}
                                options={postedWithAudiosOptions.filter((o) => o.value !== audioId)}
                                value={postedAudios}
                                onChange={(options: ValueType<AudioOption, true>) => {
                                    setUrlState({
                                        posted_with_audio: options?.length
                                            ? options.map((opt) => opt.value).toString()
                                            : undefined,
                                        page: 1,
                                    });
                                    postedWithAudioSelectProps.onChange(options);
                                    reset();
                                }}
                                components={{ Option: AudioSelectOption }}
                                styles={selectStyles}
                                placeholder="Search by tiktok id..."
                            />
                        </AudioPlayerProvider>
                    </div>

                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Exclude posted on TikTok audio</span>
                        <AudioPlayerProvider>
                            <GroupedOptionMultiSelect
                                {...excludePostedWithAudioSelectProps}
                                filterOption={null}
                                options={excludeWithAudiosOptions.filter((o) => o.value !== audioId)}
                                value={excludePostedAudios}
                                onChange={(options: ValueType<AudioOption, true>) => {
                                    setUrlState({
                                        exclude_posted_with_audio: options?.length
                                            ? options.map((opt) => opt.value).toString()
                                            : undefined,
                                        page: 1,
                                    });
                                    excludePostedWithAudioSelectProps.onChange(options);
                                    reset();
                                }}
                                components={{ Option: AudioSelectOption }}
                                styles={selectStyles}
                                placeholder="Search by tiktok id..."
                            />
                        </AudioPlayerProvider>
                    </div>

                    <div className={styles.filter}>
                        <label className={styles.filterLabel}>Exclude Plan TikTok Audio</label>
                        <div className={styles.filterCheckboxContainer}>
                            <Checkbox
                                value={isExcludePlanAudioFilterEnabled}
                                onChange={(value) => {
                                    setUrlState({
                                        should_exclude_plan_audio: value.toString(),
                                        page: 1,
                                    });
                                    reset();
                                }}
                                className={styles.filterCheckbox}
                            />
                        </div>
                    </div>

                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Genre Affinity (TT)</span>
                        <Dropdown
                            control={
                                <div className={cn(styles.filterButton)}>
                                    <MusicIcon
                                        className={cn(styles.filterIcon, {
                                            [styles.active]: !!urlState.with_genre_affinity,
                                        })}
                                    />
                                    <span>Genre Affinity</span>
                                    {!!urlState.with_genre_affinity && (
                                        <button
                                            className={styles.clearButton}
                                            onClickCapture={(e) => {
                                                e.stopPropagation();
                                                setUrlState({
                                                    with_genre_affinity: undefined,
                                                    min_relevant_post_count: undefined,
                                                    page: 1,
                                                });
                                                reset();
                                            }}
                                        >
                                            <CloseIcon />
                                        </button>
                                    )}
                                </div>
                            }
                            className={styles.popoutDropdown}
                        >
                            <article>
                                <span className={styles.filterLabel}>Genre Affinity</span>
                                <GroupedOptionMultiSelect
                                    options={genreOptions}
                                    value={selectedGenreOptions}
                                    onChange={(options) => {
                                        setUrlState({
                                            with_genre_affinity: options?.length
                                                ? options.map((opt) => opt.value).toString()
                                                : undefined,
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                    styles={selectStyles}
                                    placeholder="Select genres"
                                    isLoading={isGenresLoading}
                                    isDisabled={isGenresLoading}
                                    menuPortalTarget={null}
                                />
                            </article>

                            <article>
                                <span className={styles.filterLabel}>Min Rel. Post Count</span>
                                <DebounceInput
                                    className={cn(styles.filterInput, styles.numberFilter)}
                                    type="number"
                                    placeholder="Enter min relevant posts..."
                                    disabled={!urlState.with_genre_affinity}
                                    value={urlState.min_relevant_post_count}
                                    onChange={(e) => {
                                        setUrlState({
                                            min_relevant_post_count: e.target.value
                                                ? Number(e.target.value)
                                                : undefined,
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                    debounceTimeout={DEBOUNCE_TIMEOUT}
                                />
                            </article>
                        </Dropdown>
                    </div>

                    <div className={styles.filter}>
                        <label className={styles.filterLabel}>Hashtags (TT)</label>
                        <GroupedOptionMultiSelect
                            value={selectedHashtags}
                            onChange={(options: ValueType<GenericDropdownOption<number>, true>) => {
                                setSelectedHashtags(options);
                                setUrlState({
                                    hashtag_ids: options?.length
                                        ? options.map((option) => option.value).join()
                                        : undefined,
                                });
                                reset();
                            }}
                            styles={selectStyles}
                            {...hashtagSelectProps}
                        />
                    </div>

                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Location (TT)</span>
                        <div ref={setLocationFilterRef} className={cn(styles.filterButton)}>
                            <GlobeIcon
                                className={cn(styles.filterIcon, { [styles.active]: doLocationSelectsHaveValue })}
                            />
                            <span>Location</span>
                            {!!doLocationSelectsHaveValue && (
                                <button
                                    className={styles.clearButton}
                                    onClickCapture={(e) => {
                                        e.stopPropagation();
                                        countrySelectProps.onChange(null);
                                        citySelectProps.onChange(null);
                                        setUrlState({
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            )}
                        </div>
                        <Popover
                            anchorElement={locationFilterRef}
                            showOn="click"
                            options={{
                                placement: 'bottom-start',
                                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
                            }}
                        >
                            <Card className={styles.popoutDropdown}>
                                <article>
                                    <label className={styles.filterLabel}>Country</label>
                                    <Select
                                        styles={selectStyles}
                                        isClearable
                                        isSearchable
                                        placeholder="Select country..."
                                        {...countrySelectProps}
                                        onChange={(option) => {
                                            countrySelectProps.onChange(option);
                                            citySelectProps.onChange(null);
                                            resetCitySelect();
                                            setUrlState({
                                                page: 1,
                                            });
                                            reset();
                                        }}
                                        menuPortalTarget={null}
                                    />
                                </article>

                                <article>
                                    <label className={styles.filterLabel}>City</label>
                                    <Select
                                        styles={selectStyles}
                                        isClearable
                                        isSearchable
                                        isDisabled={!countrySelectProps.value}
                                        placeholder="Select city..."
                                        {...citySelectProps}
                                        onChange={(option) => {
                                            citySelectProps.onChange(option);
                                            setUrlState({
                                                page: 1,
                                            });
                                            reset();
                                        }}
                                        menuPortalTarget={null}
                                        getOptionLabel={(option) => option.city}
                                        formatOptionLabel={(opt) => (
                                            <HierarchicalOptionLabel labels={[opt.city, opt.state]} />
                                        )}
                                    />
                                </article>
                            </Card>
                        </Popover>
                    </div>

                    <div className={styles.filter}></div>

                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Tags</span>
                        <CategoriesFilter
                            className={styles.tagsFilter}
                            conditions={parseConditionsFromUrlString(urlState.content_tags)}
                            conditionOptions={conditionTypeOptionsWithExclude}
                            onChange={(conditions) => {
                                const urlTags = parseConditionsFromUrlString(urlState.content_tags);
                                const shouldRefreshData =
                                    !isEqual(
                                        mapConditionsToApi(conditions.filter(isIncludeCondition)),
                                        mapConditionsToApi(urlTags.filter(isIncludeCondition))
                                    ) ||
                                    !isEqual(
                                        mapExcludeConditionsToApi(conditions.filter(isExcludeCondition)),
                                        mapExcludeConditionsToApi(urlTags.filter(isExcludeCondition))
                                    );

                                setUrlState({
                                    content_tags: stringifyConditionsToUrlString(conditions),
                                    page: shouldRefreshData ? 1 : page,
                                });

                                if (shouldRefreshData) {
                                    reset();
                                }
                            }}
                            options={contentTags.map((t) => ({ value: t.id, label: t.name }))}
                            optionsLoading={isContentTagsLoading}
                            getLabel={(isFiltered) => (isFiltered ? 'Filtered by tags' : 'Tags')}
                        />
                    </div>

                    <div className={styles.filter}>
                        <label className={styles.filterLabel}>Has Account</label>
                        <div className={styles.platformFilterContainer}>
                            <div className={styles.platformFilter}>
                                <InstagramIcon className={styles.platformIcon} />
                                <Checkbox
                                    value={isHasInstagramAccountFilterEnabled}
                                    onChange={(value) => {
                                        setUrlState({
                                            has_instagram_account: value || undefined,
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                    className={styles.filterCheckbox}
                                />
                            </div>

                            <div className={styles.platformFilter}>
                                <TiktokIcon className={styles.platformIcon} />
                                <Checkbox
                                    value={isHasTiktokAccountFilterEnabled}
                                    onChange={(value) => {
                                        setUrlState({
                                            has_tiktok_account: value || undefined,
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                    className={styles.filterCheckbox}
                                />
                            </div>

                            <div className={styles.platformFilter}>
                                <YoutubeIcon className={styles.platformIcon} />
                                <Checkbox
                                    value={isHasYoutubeAccountFilterEnabled}
                                    onChange={(value) => {
                                        setUrlState({
                                            has_youtube_account: value || undefined,
                                            page: 1,
                                        });
                                        reset();
                                    }}
                                    className={styles.filterCheckbox}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={cn(styles.filter, styles.numberFilter)}>
                        <span className={styles.filterLabel}>Min past posts</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="number"
                            placeholder="Enter min past posts..."
                            value={urlState.min_tiktok_post_count}
                            onChange={(e) => {
                                setUrlState({
                                    min_tiktok_post_count: e.target.value ? Number(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={cn(styles.filter, styles.numberFilter)}>
                        <span className={styles.filterLabel}>Max past posts</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="number"
                            placeholder="Enter max past posts..."
                            value={urlState.max_tiktok_post_count}
                            onChange={(e) => {
                                setUrlState({
                                    max_tiktok_post_count: e.target.value ? Number(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={cn(styles.filter, styles.numberFilter)}>
                        <span className={styles.filterLabel}>Min post rate (%)</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="number"
                            placeholder="Enter min post rate..."
                            value={urlState.min_tiktok_post_rate}
                            onChange={(e) => {
                                setUrlState({
                                    min_tiktok_post_rate: e.target.value ? Number(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={cn(styles.filter, styles.numberFilter)}>
                        <span className={styles.filterLabel}>Min past invites</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="number"
                            placeholder="Enter min past invites..."
                            value={urlState.min_tiktok_post_invite_count}
                            onChange={(e) => {
                                setUrlState({
                                    min_tiktok_post_invite_count: e.target.value ? Number(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={cn(styles.filter, styles.numberFilter)}>
                        <span className={styles.filterLabel}>Max past invites</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="number"
                            placeholder="Enter max past invites..."
                            value={urlState.max_tiktok_post_invite_count}
                            onChange={(e) => {
                                setUrlState({
                                    max_tiktok_post_invite_count: e.target.value ? Number(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={styles.filter}>
                        <span className={styles.filterLabel}>Search</span>
                        <DebounceInput
                            className={styles.filterInput}
                            type="text"
                            placeholder="Enter search..."
                            value={urlState.search}
                            onChange={(e) => {
                                setUrlState({ search: e.target.value, page: 1 });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={styles.filter}>
                        <label className={styles.filterLabel}>Rating</label>
                        <div className={styles.ratingFilter}>
                            <RatingSelect
                                maxRating={3}
                                hideSelectedOptions={false}
                                value={urlState.rating
                                    ?.split(',')
                                    .filter((r) => !!r)
                                    .map(Number)}
                                onChange={(rating) => {
                                    setUrlState({ rating: rating?.join(), page: 1 });
                                    reset();
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.filter}>
                        <label className={styles.filterLabel}>Exclude invited</label>
                        <div className={styles.filterCheckboxContainer}>
                            <Checkbox
                                value={isExcludeInvitedFilterEnabled}
                                onChange={(value) => {
                                    setUrlState({
                                        should_exclude_invited_to_plan: value.toString(),
                                        page: 1,
                                    });
                                    reset();
                                }}
                                className={styles.filterCheckbox}
                            />
                        </div>
                    </div>
                </div>
                <Button className={styles.refreshTableButton} disabled={isTableLoading} onClick={() => init()}>
                    Refresh table
                </Button>
            </div>

            <div className={styles.actionsRow}>
                <div className={cn(styles.actionButtons, { [styles.visible]: selectedCreators.length > 0 })}>
                    <Button
                        onClick={() => {
                            setPreviewCreators(selectedCreators);
                            setIsPreviewModalOpen(true);
                        }}
                    >
                        Preview {selectedCreators.length} email{selectedCreators.length !== 1 ? 's' : ''}
                    </Button>

                    {selectedCreators.length && (
                        <Button
                            onClick={() => {
                                setSelectedCreators([]);
                            }}
                        >
                            Unselect all
                        </Button>
                    )}
                </div>

                <span className={styles.rowsCountLabel}>
                    {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                </span>
            </div>

            <CreatorsTable
                manualSortBy
                manualPagination
                disableSortBy={false}
                noDataLabel={error ?? 'No data found.'}
                loading={isTableLoading}
                data={creators}
                count={creatorsCount}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    reset();
                }}
                pageSize={page_size}
                setPageSize={(page_size) => {
                    setUrlState({ page_size: page_size ?? initialUrlState.page_size });
                    reset();
                }}
                orderBy={convertOrderingToOrderBy(ordering)}
                onOrderByChange={(orderBy) => {
                    const ordering = convertOrderByToOrdering(orderBy);
                    setUrlState({
                        ordering:
                            ordering && isOrderingParam(ordering, microwave.isMicrowaveInfluencersSortingKey)
                                ? ordering
                                : undefined,
                        page: 1,
                    });
                    reset();
                }}
                selectedRowIds={selectedCreators.map((c) => c.id)}
                onRowSelect={(creator) =>
                    setSelectedCreators((creators) => {
                        if (creators.find((c) => c.id === creator.id)) {
                            return creators.filter((c) => c.id !== creator.id);
                        }

                        return creators.concat(creator);
                    })
                }
                onAllRowsSelect={(incomingCreators) =>
                    setSelectedCreators((prev) => {
                        if (!incomingCreators.every((creator) => !!prev.find((c) => c.id === creator.id))) {
                            return prev.concat(
                                incomingCreators.filter((c) => !prev.find((creator) => creator.id === c.id))
                            );
                        }

                        return prev.filter((c) => !incomingCreators.find((creator) => creator.id === c.id));
                    })
                }
                onPaginationChange={(state) => setRowsDisplayed(state.pageRows.length)}
                onUpdate={updateCreator}
                shouldShowGenreAffinity={!!urlState.with_genre_affinity}
            />

            <Modal
                className={styles.previewModal}
                closeOnOverlayClick
                isOpen={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
            >
                {audioId && planId && previewCreators.length && (
                    <InitialEmailPreview
                        creators={previewCreators.map<PreviewUser>((creator) => ({
                            email: creator.email,
                            user_id: creator.tiktok_user_id,
                            nickname:
                                creator.tiktok_user_nickname ||
                                creator.instagram_user_username ||
                                creator.youtube_channel_title ||
                                creator.email ||
                                '-',
                        }))}
                        influencerPlanId={planId}
                        tiktokAudioId={audioId}
                        onEmailSent={(userIds) => {
                            setIsPreviewModalOpen(false);
                            setPreviewCreators([]);
                            setSelectedCreators((creators) =>
                                creators.filter((c) => !userIds.includes(c.tiktok_user_id))
                            );

                            reset();
                        }}
                        notificationType={'SONG_PROMO_INITIAL_REGISTERED'}
                    />
                )}
            </Modal>
        </div>
    );
};

export default Creators;
