import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../../ReleaseContext';
import { useCallback } from 'react';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';
import { MakeRequired } from 'utility/utility.types';

type Params = MakeRequired<Omit<microwave.GetInvitesParams, 'is_posted'>, 'campaign_id'>;

type Config = {
    params: Params;
    isDisabled?: boolean;
};

export default function usePosted({ params, isDisabled }: Config) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.posted[params.campaign_id]);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (params: Params, requestInit?: RequestInit) => {
            const campaignId = params.campaign_id;
            dispatch({ type: 'loadPosted', payload: campaignId });

            try {
                const response = await microwave.getInvites({ ...params, is_posted: true }, requestInit);
                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingPosted',
                        payload: { campaignId, message: 'Could not load posted' },
                    });
                    return response;
                }

                dispatch({ type: 'postedInitialized', payload: { campaignId, data: response.data } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postedIdle', payload: campaignId });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingPosted',
                    payload: { campaignId, message: 'Could not load posted' },
                });
                throw e;
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (isDisabled) {
                return;
            }

            fetchData(
                {
                    page: params.page || 1,
                    page_size: params.page_size,
                    campaign_id: params.campaign_id,
                    ordering: params.ordering,
                },
                { signal }
            ).catch(() => {});
        },
        [params.page, params.page_size, params.campaign_id, fetchData, isDisabled, params.ordering]
    );

    const reset = useCallback(() => dispatch({ type: 'resetPosted' }), [dispatch]);

    return {
        data: state?.data,
        status: state?.status,
        error: state?.error,
        fetchData,
        reset,
    };
}
