import { microwave, youtube } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { createContext, Dispatch, ReactNode, useCallback, useMemo, useReducer } from 'react';
import { isNumber } from 'utility/utility';

type State = {
    posts: microwave.Post[];
    influencers: microwave.MicrowaveInfluencer[];
    youtubeVideos: youtube.YoutubeVideo[];
    isInitialized: boolean;
    isLoading: boolean;
    error: string | null;
};

const initialState: State = {
    posts: [],
    influencers: [],
    youtubeVideos: [],
    isInitialized: false,
    isLoading: false,
    error: null,
};

type Actions =
    | ReducerAction<'loadPosts'>
    | ReducerActionWithPayload<
          'postsLoaded',
          Pick<State, 'posts'> & Partial<Pick<State, 'youtubeVideos' | 'influencers'>>
      >
    | ReducerActionWithPayload<'error', string | null>
    | ReducerAction<'reset'>;

const reducer = createReducer<State, Actions>({
    loadPosts: (state) => ({
        ...state,
        isLoading: true,
    }),
    error: (state, { payload }) => ({
        ...state,
        isLoading: false,
        isInitialized: true,
        error: payload,
    }),
    postsLoaded: (state, { payload }) => ({
        ...state,
        ...payload,
        error: null,
        isInitialized: true,
        isLoading: false,
    }),
    reset: () => initialState,
});

const MicrowavePostsContext = createContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode | undefined };
export const MicrowavePostsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <MicrowavePostsContext.Provider value={[state, dispatch]}>{children}</MicrowavePostsContext.Provider>;
};

export type MicrowaveCampaignPostData = microwave.Post & {
    influencer: microwave.MicrowaveInfluencer | undefined;
    youtubeVideo: youtube.YoutubeVideo | undefined;
};

export function useMicrowaveCampaignPosts() {
    const [state, dispatch] = useNonNullContext(MicrowavePostsContext);

    const fetchData = useCallback(
        async (params: microwave.GetMicrowavePostsParams, requestInit?: RequestInit) => {
            dispatch({ type: 'loadPosts' });

            try {
                const posts = await microwave.getAllPosts(params, requestInit);
                const youtubeVideosIds = posts.map((p) => p.youtube_video_id).filter(isNumber);
                const influencerIds = posts.map((p) => p.influencer_id);

                const [youtubeVideos, influencers] = await Promise.all([
                    youtubeVideosIds.length
                        ? youtube
                              .getYoutubeVideos({
                                  id: youtubeVideosIds.toString(),
                                  page_size: youtubeVideosIds.length,
                              })
                              .then((r) => {
                                  if (r.status === 200) {
                                      return r.data.results;
                                  }

                                  return null;
                              })
                        : Promise.resolve(null),
                    influencerIds.length
                        ? microwave
                              .getMicrowaveInfluencers({
                                  id: influencerIds.toString(),
                                  page_size: influencerIds.length,
                                  page: 1,
                              })
                              .then((r) => r.data.results)
                        : Promise.resolve(null),
                ]);

                dispatch({
                    type: 'postsLoaded',
                    payload: {
                        posts,
                        ...(youtubeVideos ? { youtubeVideos } : {}),
                        ...(influencers ? { influencers } : {}),
                    },
                });
                return posts;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'error', payload: 'Could not load posts' });
                throw e;
            }
        },
        [dispatch]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, [dispatch]);

    const data: MicrowaveCampaignPostData[] = useMemo(
        () =>
            state.posts.map((post) => {
                const influencer = state.influencers.find((i) => i.id === post.influencer_id);
                const youtubeVideo = state.youtubeVideos.find((v) => v.id === post.youtube_video_id);

                return {
                    ...post,
                    influencer,
                    youtubeVideo,
                };
            }),
        [state.influencers, state.posts, state.youtubeVideos]
    );

    return {
        ...state,
        data,
        fetchData,
        reset,
    };
}
