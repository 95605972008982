import { StringSearchParams, useUrlState } from '@round/utils';
import ApproveInstagramPostsTable from './Table/ApproveInstagramPostsTable';
import useApproveInstagramPosts from './useApproveInstagramPosts';
import { microwave } from '@round/api';
import { MakeRequired } from 'utility/utility.types';

type UrlState = MakeRequired<microwave.GetPaymentRequestsParams, 'page' | 'page_size'>;
const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
};

const parseUrlParams = (urlState: StringSearchParams<UrlState>) => {
    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const page_size = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    return { page, page_size };
};

const ApproveInstagramPosts = () => {
    const {
        url: { searchParams: urlState },
        mergeSearchParams,
    } = useUrlState<UrlState>((url, { merge }) => {
        return merge(url, { searchParams: initialUrlState });
    });

    const { page, page_size } = parseUrlParams(urlState);

    const { data, influencersData, status } = useApproveInstagramPosts({
        page: 1,
        page_size: 10,
    });

    const rows = (data?.results || []).map((row) => {
        const influencer = influencersData?.[row.influencer_id]?.data || null;
        return {
            ...row,
            influencer,
        };
    });

    return (
        <ApproveInstagramPostsTable
            data={rows}
            page={page}
            setPage={(page) => mergeSearchParams({ page })}
            pageSize={page_size}
            setPageSize={(pageSize) => mergeSearchParams({ page_size: pageSize })}
            count={data?.count || 0}
            meta={{
                isLoading: status === 'loading' || status === 'idle',
                //TODO: update state after patch
                updatePaymentRequest: microwave.patchPaymentRequest,
            }}
        />
    );
};

export default ApproveInstagramPosts;
