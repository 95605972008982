import { combineReducers } from 'helpers';
import {
    reducer as paymentRequestsReducer,
    initialState as paymentRequestsInitialState,
} from 'Modules/Advertising/Microwave/hooks/dataHooks/usePaymentRequestsDataHook';
import {
    reducer as influencersReducer,
    initialState as influencersInitialState,
} from 'Modules/Advertising/Microwave/hooks/dataHooks/useCreatorsByIdDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

//Currently we just have instagram data included in state, but we will later migrate tiktok data here as well
export const initialState: State = {
    influencers: influencersInitialState,
    instagram: {
        paymentRequests: paymentRequestsInitialState,
    },
};

export const reducer = combineReducers({
    influencers: influencersReducer,
    instagram: combineReducers({
        paymentRequests: paymentRequestsReducer,
    }),
});
