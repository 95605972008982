import React, { useCallback, useMemo, useState } from 'react';
import styles from './InfluencerPlanGroupBar.module.css';
import { ReactComponent as ArrowIcon } from '../../../../../assets/ArrowIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/TrashIcon.svg';
import cn from 'classnames';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { ReactComponent as CopyIcon } from '../../../../../assets/CopyIcon.svg';
import { ReactComponent as EditIcon } from '../../../../Advertising/assets/EditIcon.svg';
import { ReactComponent as InfoCircleIcon } from '../../../../../assets/InfoCircle.svg';
import { displayOptionalNumericTableValue, numberWithCommas, roundTo2Dp } from '../../../../../helpers';
import CurrencyInput from 'react-currency-input-field';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import { InstagramInfluencerPost } from '../../types/Instagram.types';
import { FCWithChildren } from '../../../../../utility/utility.types';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import { Popover } from '@round/ui-kit';
import Button from '../../../../../ui/Buttons/Button/Button';
import Card from '../../../../../ui/Card/Card';
import Tooltip from '../../../../../ui/DataDisplay/Tooltip/Tooltip';
import { postGroupIsHiddenWarningStatuses } from '../../InfluencerPlan.helpers';
import { InfluencerPostGroup, TiktokInfluencerPost } from '@round/api';
import Toggle from '../../../../../ui/DataEntry/Toggle/Toggle';
import { TiktokInfluencerPostGroupStatsWithId } from '../../contexts/TiktokCreatorsContext/reducer';
import { InstagramInfluencerPostGroupStatsWithId } from '../../contexts/InstagramCreatorsContext/reducer';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import InvoiceRequestStatusBadge from '../../../../Finance/InvoiceRequests/InvoiceRequestStatusBadge/InvoiceRequestStatusBadge';
import { ReactComponent as MusicNoteIcon } from 'assets/MusicNote.svg';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

interface InfluencerPlanGroupBarProps {
    platform: 'tiktok' | 'instagram';
    name: string;
    group: InfluencerPostGroup;
    groupStats?: TiktokInfluencerPostGroupStatsWithId | InstagramInfluencerPostGroupStatsWithId;
    groups: InfluencerPostGroup[];
    groupQuantity: number;
    index: number;
    expandedGroups: number[];
    items?: (InstagramInfluencerPost | TiktokInfluencerPost)[];
    currencySymbol?: string;
    addPost: (groupId: number) => void;
    deleteGroup: (group: InfluencerPostGroup) => void;
    updateGroup: (platform: string, data: Partial<InfluencerPostGroup>) => void;
    increaseGroupOrder: (platform: string, group: InfluencerPostGroup) => void;
    decreaseGroupOrder: (platform: string, group: InfluencerPostGroup) => void;
    checkGroup: (position: number) => void;
    copyReportTable?: () => void;
    copyProposalTable?: () => void;
    copyBrief: () => void;
    onClickEditBrief: (group: InfluencerPostGroup) => void;
    onViewAudio?: (group: InfluencerPostGroup) => void;
}

export const InfluencerPlanGroupBar: FCWithChildren<InfluencerPlanGroupBarProps> = ({
    platform,
    name,
    group,
    groupStats,
    groups = [],
    groupQuantity,
    expandedGroups,
    index,
    items = [],
    currencySymbol,
    addPost,
    deleteGroup,
    updateGroup,
    increaseGroupOrder,
    decreaseGroupOrder,
    checkGroup,
    copyReportTable,
    copyProposalTable,
    copyBrief,
    onClickEditBrief,
    onViewAudio,
}) => {
    const isGroupsWithAudioEnabled = useFeatureFlags().isEnabled('influencer_post_group_with_audio');
    const [copyToClipboardButtonRef, setCopyToClipboardButtonRef] = useState<HTMLButtonElement | null>(null);
    const [isHiddenWarningRef, setIsHiddenWarningRef] = useState<SVGSVGElement | null>(null);
    const isUserStaff = useCheckUserGroupsAccess(['influencer_editor']);

    const [groupName, setGroupName] = useState(name);
    const [budget, setBudget] = useState(group.budget);
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const startDate = useMemo(() => {
        if (platform !== 'tiktok') {
            return null;
        }

        const stats = groupStats as TiktokInfluencerPostGroupStatsWithId | undefined;
        return stats?.first_post_dt ? new Date(stats?.first_post_dt) : null;
    }, [groupStats, platform]);

    const endDate = useMemo(() => {
        if (platform !== 'tiktok') {
            return null;
        }

        const stats = groupStats as TiktokInfluencerPostGroupStatsWithId | undefined;
        return stats?.last_post_dt ? new Date(stats?.last_post_dt) : null;
    }, [groupStats, platform]);

    const onIncreaseGroupOrder = useCallback(() => {
        if (index !== 0) {
            increaseGroupOrder(platform, group);
            decreaseGroupOrder(platform, groups[index - 1]);
        }
    }, [platform, group, groups, index, increaseGroupOrder, decreaseGroupOrder]);

    const onDecreaseGroupOrder = useCallback(() => {
        if (index !== groupQuantity - 1) {
            decreaseGroupOrder(platform, group);
            increaseGroupOrder(platform, groups[index + 1]);
        }
    }, [platform, group, groups, index, increaseGroupOrder, decreaseGroupOrder, groupQuantity]);

    const onDelete = useCallback(() => deleteGroup(group), [deleteGroup, group]);
    const amountSpentPercent = groupStats?.amount_spent ? groupStats.amount_spent * 100 : 0;

    const postCountText = useMemo(() => {
        const postCount = groupStats?.post_count ?? 0;
        const livePostCount = groupStats?.live_post_count ?? 0;

        return postCount === 0 ? '0 posts' : `${livePostCount}/${postCount} post${postCount !== 1 ? 's' : ''} live`;
    }, [groupStats?.post_count, groupStats?.live_post_count]);

    const cpmValue = useMemo(() => {
        return groupStats?.total_cpm.toFixed(2) ?? '-';
    }, [groupStats?.total_cpm]);

    const engagementRateValue = useMemo(() => {
        if (groupStats?.engagement_rate) {
            return `${roundTo2Dp(groupStats?.engagement_rate * 100)}%`;
        }

        return '-';
    }, [groupStats?.engagement_rate]);

    const showGroupHiddenWarning =
        !!items.filter((item) => postGroupIsHiddenWarningStatuses.includes(item.status)).length && group.is_hidden;

    const invoiceRequestStatus = invoiceRequestStatusOptions.find((o) => o.value === group.invoice_request_status);

    return (
        <section
            className={cn(styles.row, styles.wrapper, {
                [styles.groupHidden]: group.is_hidden,
            })}
            data-test-id={`influencer-post-group-${group.id}`}
        >
            <button
                data-test-id={`expand-group-${group.id}-button`}
                className={cn(styles.groupButton, styles.expandButton, {
                    [styles.expanded]: expandedGroups.includes(group.id),
                })}
                onClick={() => checkGroup(group.id)}
            >
                <ArrowIcon />
            </button>
            <div className={styles.groupInfoColumns}>
                <div className={cn(styles.groupInfoColumn, styles.groupName)}>
                    <input
                        className={cn(styles.groupInfoColumnHeading, styles.groupBarInput)}
                        type="text"
                        name="group-name"
                        value={groupName}
                        onChange={({ target: { value } }) => setGroupName(value)}
                        onBlur={() => updateGroup(platform, { name: groupName })}
                        onKeyDown={({ key }) => key === 'Enter' && updateGroup(platform, { name: groupName })}
                        disabled={!canEdit}
                    />
                    <span className={styles.groupInfoColumnValue}>{postCountText}</span>
                </div>
                <div className={cn(styles.groupInfoColumn, styles.amountSpent)}>
                    <div className={cn(styles.groupInfoColumnValue, styles.amountSpentValue)}>
                        <CurrencyInput
                            className={cn(styles.groupBarInput, styles.amountSpentInput)}
                            prefix={currencySymbol}
                            value={budget}
                            onValueChange={(value) => setBudget(value ?? '')}
                            disabled={!canEdit}
                            name="group-budget"
                            onBlur={() => updateGroup(platform, { budget })}
                            onKeyDown={({ key }) => key === 'Enter' && updateGroup(platform, { budget })}
                            groupSeparator=","
                            decimalSeparator="."
                        />
                        <span>{numberWithCommas(roundTo2Dp(amountSpentPercent))}%</span>
                    </div>
                    <ProgressBar
                        trackStyles={{
                            width: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            height: '0.33rem',
                            marginTop: '0.33rem',
                        }}
                        barStyles={{
                            backgroundColor: 'white',
                        }}
                        progress={amountSpentPercent}
                    />
                </div>

                <div className={styles.groupInfoColumn}>
                    <span className={styles.groupInfoColumnHeading}>{isUserStaff ? 'Client CPM' : 'CPM'}</span>
                    <span className={styles.groupInfoColumnValue}>
                        {currencySymbol}
                        {displayOptionalNumericTableValue(cpmValue)}
                    </span>
                </div>

                <div className={styles.groupInfoColumn}>
                    <span className={styles.groupInfoColumnHeading}>Engagement Rate</span>
                    <span className={styles.groupInfoColumnValue}>{engagementRateValue}</span>
                </div>

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <div className={cn(styles.groupInfoColumn, styles.invoiceRequestStatus)}>
                        <span className={styles.groupInfoColumnHeading}>Invoice Request Status</span>
                        <span className={styles.groupInfoColumnValue}>
                            {invoiceRequestStatus ? (
                                <InvoiceRequestStatusBadge status={invoiceRequestStatus.value} />
                            ) : (
                                '-'
                            )}
                        </span>
                    </div>
                </ProtectedByUserGroups>

                {platform === 'tiktok' && startDate && endDate && (
                    <div className={styles.groupInfoColumn}>
                        <span className={styles.groupInfoColumnHeading}>Duration</span>
                        <span className={styles.groupInfoColumnValue}>
                            {startDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' })}
                            {' - '}
                            {endDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' })}
                        </span>
                    </div>
                )}

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <div className={cn(styles.groupInfoColumn, styles.liveToggle)}>
                        <div className={styles.row}>
                            <Toggle
                                value={!group.is_hidden}
                                onChange={(value) => updateGroup(platform, { is_hidden: !value })}
                                className={cn(styles.hiddenToggle, {
                                    [styles.hiddenToggleOn]: !group.is_hidden,
                                    [styles.warningToggle]: showGroupHiddenWarning,
                                })}
                            />
                            {showGroupHiddenWarning && (
                                <>
                                    <Tooltip
                                        anchorElement={isHiddenWarningRef}
                                        value={
                                            "This group is set to 'In proposal', but contains posts which are live or have been approved."
                                        }
                                    />
                                    <InfoCircleIcon ref={setIsHiddenWarningRef} className={styles.warningIcon} />
                                </>
                            )}
                        </div>
                        <span className={cn(styles.groupInfoColumnValue, styles.hiddenToggleValue)}>
                            {group.is_hidden ? 'In proposal' : 'Live'}
                        </span>
                    </div>
                </ProtectedByUserGroups>
            </div>

            <div className={cn(styles.actionButtons, { [styles.editorActionButtons]: canEdit })}>
                {isGroupsWithAudioEnabled && group.audio_id && (
                    <button className={styles.groupButton} onClick={() => onViewAudio?.(group)}>
                        <MusicNoteIcon /> View audio
                    </button>
                )}

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <button className={styles.groupButton} onClick={() => onClickEditBrief(group)}>
                        <EditIcon />
                        Edit brief
                    </button>

                    <button className={styles.groupButton} onClick={() => addPost(group.id)}>
                        + New post
                    </button>
                    <button className={styles.groupButton} onClick={onDelete}>
                        <TrashIcon />
                    </button>
                </ProtectedByUserGroups>

                <button ref={setCopyToClipboardButtonRef} className={styles.groupButton}>
                    <CopyIcon className={styles.icon} />
                </button>

                <Popover anchorElement={copyToClipboardButtonRef} showOn="click">
                    <Card className={styles.copyToTableDropdown}>
                        <ProtectedByUserGroups groups={['influencer_editor']}>
                            <Button onClick={copyProposalTable}>Proposal</Button>
                            <Button onClick={copyBrief}>Brief</Button>
                        </ProtectedByUserGroups>
                        <Button onClick={copyReportTable}>Report</Button>
                    </Card>
                </Popover>

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <div className={cn(styles.groupButton, styles.reorderingButtons)}>
                        <button className={styles.reorderingButton} onClick={onIncreaseGroupOrder}>
                            <ArrowIcon height={8} />
                        </button>
                        <button
                            className={cn(styles.reorderingButton, styles.increaseOrderIndexButton)}
                            onClick={onDecreaseGroupOrder}
                        >
                            <ArrowIcon height={8} />
                        </button>
                    </div>
                </ProtectedByUserGroups>
            </div>
        </section>
    );
};
