import { microwave } from '@round/api';
import styles from './DecisionCell.module.css';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import Button from 'ui/Button/Button';
import cn from 'classnames';
import { CellContext } from '@tanstack/react-table';

type BaseMeta = {
    isLoading: boolean | undefined;
};

export type Meta = {
    updatePaymentRequest: typeof microwave.patchPaymentRequest;
};

/**
 * Cell component for the decision column in the table
 *
 * **Note**: Requires accessor for the payment request status
 */
const DecisionCell = <TRow extends microwave.PaymentRequest, TMeta extends BaseMeta & Meta>({
    getValue,
    row,
    table,
}: CellContext<TRow, microwave.PaymentRequestStatus>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading, updatePaymentRequest } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    const value = getValue();
    const id = row.original.id;

    return (
        <div className={styles.container}>
            <Button
                appearance="primary"
                className={cn(styles.button, { [styles.disabled]: value === 'APPROVED' })}
                disabled={value === 'APPROVED'}
                onClick={() => updatePaymentRequest(id, { status: 'APPROVED' })}
            >
                Approve
            </Button>
            <Button
                appearance="contrast"
                className={cn(styles.button, { [styles.disabled]: value === 'REJECTED' })}
                disabled={value === 'REJECTED'}
                onClick={() => updatePaymentRequest(id, { status: 'REJECTED' })}
            >
                Reject
            </Button>
        </div>
    );
};

export default DecisionCell;
