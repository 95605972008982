import { createSelectableContext } from 'Hooks/useNonNullContextSelector';
import { Actions, initialState, reducer, State } from './reducer';
import { Dispatch, ReactNode, useReducer } from 'react';

export const ApprovalsContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode };
export const ApprovalsProvider = ({ children }: Props) => {
    return <ApprovalsContext.Provider value={useReducer(reducer, initialState)}>{children}</ApprovalsContext.Provider>;
};
