import { getTableMetaHelper } from '@round/ui-kit';
import { CellContext, RowData } from '@tanstack/react-table';
import Skeleton from 'react-loading-skeleton';
import CampaignName from '../CampaignName/CampaignName';

type BaseMeta = {
    isLoading: boolean | undefined;
};

const ReportCampaignCell = <TMeta extends BaseMeta, TRow extends RowData>({
    table,
    getValue,
}: CellContext<TRow, string | null | undefined>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    return <CampaignName name={getValue()} />;
};

export default ReportCampaignCell;
