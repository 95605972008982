import { DataState } from '@round/utils';
import { microwave, PaginatedApiResponseData } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = {
    [campaignId: number]: DataState<PaginatedApiResponseData<microwave.Invite> | null>;
};

type Actions =
    | ReducerActionWithPayload<'postedIdle', number>
    | ReducerActionWithPayload<'loadPosted', number>
    | ReducerActionWithPayload<
          'postedInitialized',
          {
              campaignId: number;
              data: PaginatedApiResponseData<microwave.Invite>;
          }
      >
    | ReducerActionWithPayload<'errorLoadingPosted', { campaignId: number; message: string }>
    | ReducerAction<'resetPosted'>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    postedIdle: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: null,
            status: 'idle',
        },
    }),
    loadPosted: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            status: 'loading',
            error: null,
        },
    }),
    errorLoadingPosted: (state, { payload: { campaignId, message } }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: message,
            status: 'error',
        },
    }),
    postedInitialized: (state, { payload: { campaignId, data } }) => {
        return {
            ...state,
            [campaignId]: {
                data,
                error: null,
                status: 'success',
            },
        };
    },
    resetPosted: () => initialState,
});
