import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Campaign, CampaignStats } from './campaigns.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    microwaveCampaign: Model as ModelDefinition<Campaign>,
    microwaveCampaignStat: Model as ModelDefinition<CampaignStats>,
};

export const serializers = {
    microwaveCampaign: makeSerializer<Campaign>([]),
    microwaveCampaignStat: makeSerializer<CampaignStats>([]),
};

export const factories = {
    microwaveCampaign: createFactory<Campaign>({
        name: 'Micros',
        budget: (index: number) => index,
        platform: 'tiktok',
        target_post_count: (index: number) => index,
        currency_id: 1,
    }),
    microwaveCampaignStat: createFactory<CampaignStats>({
        amount_spent: 0,
        cpm: 0,
        engagement_rate: 0,
        post_count: 0,
        total_views: 0,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/campaigns/', function (this: RouteHandlerContext, schema, request) {
        const campaigns = schema.all('microwaveCampaign');
        return buildPaginatedResponse(campaigns, {
            url: '/api/microwave/campaigns/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwaveCampaign'),
        });
    });

    server.get('/api/microwave/campaigns/:id/stats/', function (this: RouteHandlerContext, schema, request) {
        const stats = schema.find('microwaveCampaignStat', request.params.id);
        if (!stats) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return stats;
    });

    server.post('/api/microwave/campaigns/', function (this: RouteHandlerContext, schema, request) {
        const data = JSON.parse(request.requestBody);
        const campaign = schema.create('microwaveCampaign', data);
        return schema.find('microwaveCampaign', campaign.id);
    });

    server.post(`/api/microwave/campaigns/:id/invites`, (schema, request) => {
        const numberOfEmailRequests = JSON.parse(request.requestBody).length;

        return new Response(201, {}, { success: numberOfEmailRequests });
    });
}
