import { TabNavItem } from '@round/ui-kit';
import styles from './UnderlineTabs.module.css';
import cn from 'classnames';
import { TabNavItemProps } from '@round/ui-kit';

const UnderLineTabs = () => {};

type BarProps = { children?: React.ReactNode; className?: string };
const Bar = ({ children, className }: BarProps) => {
    return <menu className={cn(styles.bar, className)}>{children}</menu>;
};

type ItemProps = TabNavItemProps;

const Item = ({ name, children, className, activeClassName }: ItemProps) => {
    return (
        <li>
            <TabNavItem
                name={name}
                className={cn(styles.item, className)}
                activeClassName={cn(styles.active, activeClassName)}
            >
                {children}
            </TabNavItem>
        </li>
    );
};

UnderLineTabs.Bar = Bar;
UnderLineTabs.Item = Item;

export default UnderLineTabs;
