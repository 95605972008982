import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { encodeUrlSearchParams, parseUrlSearchParams } from '../url';
import { Primitives } from '../types';

export type BaseSearchParams = Record<string, Primitives | Primitives[]>;
// Search params that come from the URL string unprocessed
export type StringSearchParams<T extends BaseSearchParams = {}> = Partial<Record<keyof T, string>>;

export type UrlData<TSearchParams extends BaseSearchParams = {}> = {
    searchParams: TSearchParams;
    // hash field operates without hash symbol<br />
    // `'some-page-section'` and **not** `'#some-page-section'`
    hash: string | undefined;
};

export type WriteSearchParamsData<T extends BaseSearchParams = {}> = Partial<
    Record<keyof T, Primitives | Primitives[]>
>;
type UrlInit<T extends BaseSearchParams = {}> =
    | Partial<UrlData<WriteSearchParamsData<T>>>
    | ((
          prevUrlData: UrlData<StringSearchParams<T>>,
          helpers: InitHelpers
      ) => Partial<UrlData<WriteSearchParamsData<T>>>);

type UrlSetter<T extends BaseSearchParams = {}> =
    | Partial<UrlData<WriteSearchParamsData<T>>>
    | ((prevUrlData: UrlData<StringSearchParams<T>>) => Partial<UrlData<WriteSearchParamsData<T>>>);

type UrlSearchParamsSetter<T extends BaseSearchParams = {}> =
    | Partial<WriteSearchParamsData<T>>
    | ((prevSearchParams: StringSearchParams<T>) => Partial<WriteSearchParamsData<T>>);

export default function useUrlState<TSearchParams extends BaseSearchParams = {}>(init?: UrlInit<TSearchParams>) {
    const mountedRef = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();
    const urlData: UrlData<StringSearchParams<TSearchParams>> = useMemo(() => {
        return {
            searchParams: parseUrlSearchParams(location.search) as StringSearchParams<TSearchParams>,
            hash: location.hash.startsWith('#') ? location.hash.slice(1) : location.hash,
        };
    }, [location.hash, location.search]);

    const set = useCallback(
        (setter?: UrlSetter<TSearchParams>) => {
            const incomingUrlData = typeof setter === 'function' ? setter(urlData) : setter;
            navigate(
                {
                    search: encodeUrlSearchParams(incomingUrlData?.searchParams ?? {}),
                    hash: incomingUrlData?.hash,
                },
                { replace: true }
            );
        },
        [navigate, urlData]
    );

    const setSearchParams = useCallback(
        (setter?: UrlSearchParamsSetter<TSearchParams>) => {
            const incomingSearchParams = typeof setter === 'function' ? setter(urlData.searchParams) : setter;
            set((prev) => ({ searchParams: incomingSearchParams, hash: prev.hash }));
        },
        [set, urlData.searchParams]
    );

    const merge = useCallback(
        (setter?: UrlSetter<TSearchParams>) => {
            const incomingUrlData = typeof setter === 'function' ? setter(urlData) : setter;
            navigate(
                {
                    search: encodeUrlSearchParams({
                        ...urlData.searchParams,
                        ...(incomingUrlData?.searchParams ?? {}),
                    }),
                    hash: Object.hasOwnProperty.call(incomingUrlData, 'hash') ? incomingUrlData?.hash : urlData.hash,
                },
                { replace: true }
            );
        },
        [navigate, urlData]
    );

    const mergeSearchParams = useCallback(
        (setter?: UrlSearchParamsSetter<TSearchParams>) => {
            const incomingSearchParams = typeof setter === 'function' ? setter(urlData.searchParams) : setter;
            merge({ searchParams: incomingSearchParams });
        },
        [merge, urlData.searchParams]
    );

    useEffect(() => {
        if (!mountedRef.current) {
            // fallback to existing url on init so we allow readonly for url state
            // otherwise empty init would reset the url
            // to reset we'd need to explicitly pass empty UrlData on init
            const incomingUrlData =
                typeof init === 'function' ? init(urlData, { merge: mergeUrlData }) : init ?? urlData;
            navigate(
                {
                    search: encodeUrlSearchParams(incomingUrlData?.searchParams ?? {}),
                    hash: incomingUrlData?.hash,
                },
                { replace: true }
            );
        }

        mountedRef.current = true;
    }, [init, navigate, urlData]);

    return { url: urlData, set, setSearchParams, merge, mergeSearchParams };
}

export type InitHelpers = {
    merge: typeof mergeUrlData;
};

function mergeUrlData<T extends BaseSearchParams = {}>(
    baseUrlData: UrlData<StringSearchParams<T>>,
    urlData: Partial<UrlData<WriteSearchParamsData<T>>>
): Partial<UrlData<WriteSearchParamsData<T>>> {
    return {
        searchParams: {
            ...baseUrlData.searchParams,
            ...urlData.searchParams,
        },
        hash: Object.hasOwnProperty.call(urlData, 'hash') ? urlData?.hash : baseUrlData.hash,
    };
}
