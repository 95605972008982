import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import ApproveTiktokPosts from './ApproveTiktokPosts/ApproveTiktokPosts';
import { useUrlState } from '@round/utils';
import { TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTok.svg';
import styles from './ApproveMicrowavePosts.module.css';
import UnderLineTabs from 'ui-new/Tabs/UnderlineTabs/UnderLineTabs';
import ApproveInstagramPosts from './ApproveInstagramPosts/ApproveInstagramPosts';
import { ApprovalsProvider } from './context/ApprovalsContext';

const tabOptions = ['tiktok', 'instagram'] as const;
const isTabOption = (value: string): value is typeof tabOptions[number] => !!tabOptions.find((opt) => opt === value);

const ApproveMicrowavePosts = () => {
    const shouldShowInstagramApprovals = useFeatureFlags().isEnabled('microwave_instagram_approvals');

    const { url, merge: mergeUrlData } = useUrlState();

    const selectedTab = url.hash && isTabOption(url.hash) ? url.hash : tabOptions[0];

    if (!shouldShowInstagramApprovals) {
        return <ApproveTiktokPosts />;
    }

    return (
        <Tabs activeTab={selectedTab} onChange={(tab) => mergeUrlData({ hash: tab })}>
            <div className={styles.container}>
                <h1 className={styles.title}>Approve Posts</h1>
                <UnderLineTabs.Bar>
                    <UnderLineTabs.Item name="tiktok">
                        <TikTokIcon className={styles.platformIcon} /> TikTok
                    </UnderLineTabs.Item>
                    <UnderLineTabs.Item name="instagram">
                        <InstagramIcon className={styles.platformIcon} /> Instagram
                    </UnderLineTabs.Item>
                </UnderLineTabs.Bar>

                <div>
                    <TabPanels>
                        <TabPanel name="tiktok">
                            <ApproveTiktokPosts />
                        </TabPanel>
                        <TabPanel name="instagram">
                            <ApprovalsProvider>
                                <ApproveInstagramPosts />
                            </ApprovalsProvider>
                        </TabPanel>
                    </TabPanels>
                </div>
            </div>
        </Tabs>
    );
};

export default ApproveMicrowavePosts;
