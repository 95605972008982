import { TiktokAudio, TiktokAudioAsset, getTiktokAudioAssets, getTiktokAudios, microwave } from '@round/api';
import { useCallback, useState } from 'react';
import uniq from 'lodash/uniq';
import { getAllInfluencerPlans } from '../../../InfluencerPlan/InfluencerPlan.api';
import { InfluencerPlan } from 'App.types';
import { ApproveTiktokPostsTableRow } from './Table/ApproveTiktokPostsTable';
import useMicrowaveTiktokPosts, { Params } from '../../useMicrowaveTiktokPosts';
import { isNumber } from 'utility/utility';
import useAbortableEffect from 'Hooks/useAbortableEffect';

export function useTiktokApprovalsData(params: Params) {
    const {
        posts,
        count,
        isInitialized: arePostsInitialized,
        isLoading: arePostsLoading,
        hasError: hasErrorLoadingPosts,
        init: initPosts,
        reset: resetPosts,
        removePost,
    } = useMicrowaveTiktokPosts(params);

    const [plans, setPlans] = useState<InfluencerPlan[]>([]);
    const [arePlansLoading, setArePlansLoading] = useState(false);
    const [hasErrorLoadingPlans, setHasErrorLoadingPlans] = useState(false);

    const [influencers, setInfluencers] = useState<microwave.MicrowaveInfluencer[]>([]);
    const [areInfluencersLoading, setAreInfluencersLoading] = useState(false);

    const fetchInfluencers = useCallback(async (ids: number[], requestInit?: RequestInit) => {
        if (!ids.length) {
            return;
        }

        setAreInfluencersLoading(true);
        try {
            const response = await microwave.getMicrowaveInfluencers(
                { id: ids.toString(), page: 1, page_size: ids.length },
                requestInit
            );
            setInfluencers((prev) => prev.concat(response.data.results));
        } catch {
            // no-op
        } finally {
            setAreInfluencersLoading(false);
        }
    }, []);

    const fetchPlans = useCallback(async (ids: number[], requestInit?: RequestInit) => {
        setHasErrorLoadingPlans(false);
        if (!ids.length) {
            return;
        }

        setArePlansLoading(true);
        try {
            const plans = await getAllInfluencerPlans({ id: ids.toString() }, requestInit);
            setPlans((prev) => prev.concat(plans));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            setHasErrorLoadingPlans(false);
        } finally {
            setArePlansLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            const existingPlansIds = plans.map((p) => p.id);
            const postPlanIds = uniq(posts.map((p) => p.plan).filter(isNumber));
            const plansToFetch = postPlanIds.filter((planId) => !existingPlansIds.includes(planId));

            const existingInfluencersIds = influencers.map((inf) => inf.id);
            const influencersIds = uniq(posts.map((post) => post.influencer_id).filter(isNumber));
            const influencersToFetch = influencersIds.filter((id) => !existingInfluencersIds.includes(id));

            if (!arePostsInitialized) {
                return;
            }

            Promise.all([fetchPlans(plansToFetch, { signal }), fetchInfluencers(influencersToFetch, { signal })]);
        },
        [arePostsInitialized, fetchInfluencers, fetchPlans, influencers, plans, posts]
    );

    const [audios, setAudios] = useState<TiktokAudio[]>([]);
    const [assets, setAssets] = useState<TiktokAudioAsset[]>([]);
    const [areAudiosLoading, setAreAudiosLoading] = useState(false);
    const [hasErrorLoadingAudios, setHasErrorLoadingAudios] = useState(false);

    useAbortableEffect(
        (signal) => {
            const existingAudioIds = audios.map((a) => a.id);
            const postAudioIds = uniq(posts.map((p) => p.audio_id).filter(isNumber));
            const audiosToFetch = postAudioIds.filter((audioId) => !existingAudioIds.includes(audioId));
            if (!audiosToFetch.length || !arePostsInitialized) {
                return;
            }

            setHasErrorLoadingAudios(false);
            setAreAudiosLoading(true);

            Promise.all([
                getTiktokAudios({ id: audiosToFetch.join(','), page_size: audiosToFetch.length }, { signal }),
                getTiktokAudioAssets(audiosToFetch, { signal }),
            ])
                .then(([audioResponse, assets]) => {
                    setAudios((prev) => prev.concat(audioResponse.data.results));
                    setAssets((prev) => prev.concat(assets));
                })
                .finally(() => setAreAudiosLoading(false));
        },
        [arePostsInitialized, audios, posts]
    );

    const updatePaymentRequestStatus = async (id: number, status: microwave.PaymentRequestStatus) => {
        try {
            const response = await microwave.patchTiktokPostPaymentRequestStatus(id, status);
            return response.status === 200;
        } catch {
            return false;
        }
    };

    const rows: ApproveTiktokPostsTableRow[] = posts.map((post) => {
        const plan = plans.find((plan) => plan.id === post.plan);
        const audio = audios.find((audio) => audio.id === post.audio_id);
        const asset = assets.find((asset) => asset.audio_id === post.audio_id);
        const audioImageUrl = asset?.image_thumb.cached_url || asset?.image_thumb.original_url;
        const influencer = influencers.find((inf) => inf.id === post.influencer_id);
        return {
            ...post,
            influencer_plan: plan,
            audio,
            audioImageUrl,
            influencer,
        };
    });

    const reset = useCallback(() => {
        resetPosts();
        setHasErrorLoadingPlans(false);
        setArePlansLoading(false);
    }, [resetPosts]);

    return {
        rows,
        count,
        isLoading: arePostsLoading || arePlansLoading || areAudiosLoading || areInfluencersLoading,
        isInitialized: arePostsInitialized,
        hasError: hasErrorLoadingPosts || hasErrorLoadingPlans || hasErrorLoadingAudios,

        init: initPosts,
        removePostFromState: removePost,
        reset,
        updatePaymentRequestStatus,
    };
}
