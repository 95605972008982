import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import useEmailTemplatesSelect from 'Modules/Advertising/Microwave/hooks/useEmailTemplatesSelect';
import { notificationTypeLabels } from 'Modules/Advertising/Microwave/MicroCreators/helpers';
import Select from 'ui-new/Select/Select';
import styles from './EmailPreviewModal.module.css';
import EmailContent from '../../MicroCreators/EmailPreview/EmailContent/EmailContent';
import { useEmailPreview } from './useEmailPreview';
import useMicrowaveInfluencerSelect, {
    buildFetchOptions,
    MicrowaveInfluencerOption,
} from '../../hooks/useMicrowaveInfluencerSelect';
import { useMemo } from 'react';
import { ValueType } from 'react-select';
import { useEmailAddressOverrides } from './hooks/useEmailAddressOverrides';
//TODO: extract input component from FormField.module.css
import inputStyles from 'ui-new/formik/FormField.module.css';
import { useSendInitialEmails } from './hooks/useSendEmails';
import Button from 'ui/Button/Button';
import EmailResults from './components/EmailResults/EmailResults';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    campaignId: number;
    influencerIds: number[];
};

const notificationType = 'SONG_PROMO_INITIAL_REGISTERED';

const InitialEmailPreviewModal = ({ isOpen, onClose, campaignId, influencerIds }: Props) => {
    const {
        selectedTemplate,
        setSelectedTemplate,
        templateOptions,
        status: templatesStatus,
        error: templatesError,
    } = useEmailTemplatesSelect(notificationType);

    const influencerIdStringQuery = influencerIds.join(',');
    const {
        props: influencerSelectProps,
        resetOptions: resetInfluencerOptions,
        resetValue: resetInfluencerSelectValue,
    } = useMicrowaveInfluencerSelect({
        fetchOptions: useMemo(() => buildFetchOptions({ id: influencerIdStringQuery }), [influencerIdStringQuery]),
        initialValueId: isOpen ? influencerIds[0] : undefined,
        initOn: 'menuOpen',
    });

    const selectedInfluencer = influencerSelectProps.value as ValueType<MicrowaveInfluencerOption, false>;

    const {
        data: emailPreviewData,
        status: emailPreviewStatus,
        error: emailPreviewError,
        reset: resetEmailPreview,
    } = useEmailPreview({
        params: {
            campaign_id: campaignId,
            email_template_id: selectedTemplate?.value!,
            influencer_id: selectedInfluencer?.value!,
        },
        isDisabled: !isOpen || !selectedTemplate?.value || !selectedInfluencer?.value,
    });

    const { selectedOverride, setSelectedOverride, overrides, resetOverrides } = useEmailAddressOverrides<number>(
        selectedInfluencer?.value
    );
    const currentEmailValue =
        typeof selectedOverride === 'string' ? selectedOverride : selectedInfluencer?.emailAddress;

    const { send, data: sendResults, status: sendStatus, reset: resetSendData } = useSendInitialEmails();
    const handleSend = async () => {
        if (!selectedTemplate?.value) {
            return;
        }

        const emailData = influencerIds.map((id) => {
            const relevantOverride = overrides.find((o) => o.id === id);

            return {
                influencer_id: id,
                email_template_id: selectedTemplate.value,
                email_address: relevantOverride?.emailAddress,
            };
        });

        await send(campaignId, emailData).catch(() => {});
    };

    const handleClose = () => {
        onClose();
        resetEmailPreview();
        resetInfluencerOptions();
        resetInfluencerSelectValue();
        resetOverrides();
        resetSendData();
    };

    return (
        <FullScreenModal isOpen={isOpen} onClose={handleClose}>
            {sendStatus === 'success' ? (
                <>
                    <FullScreenModal.Header>Results</FullScreenModal.Header>
                    <EmailResults results={sendResults} />
                    <FullScreenModal.Footer>
                        <Button appearance="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </FullScreenModal.Footer>
                </>
            ) : (
                <>
                    <FullScreenModal.Header>Email Preview</FullScreenModal.Header>
                    <FullScreenModal.Content className={styles.container}>
                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>
                                {notificationTypeLabels[notificationType]} Templates
                            </label>
                            <Select
                                isLoading={templatesStatus === 'not-initialized'}
                                options={templateOptions}
                                value={selectedTemplate}
                                onChange={(opt) => {
                                    if (opt?.value === selectedTemplate?.value) {
                                        return;
                                    }

                                    setSelectedTemplate(opt);
                                    resetEmailPreview();
                                }}
                                noOptionsMessage={() => templatesError || 'No options'}
                            />
                        </div>

                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>Influencer</label>
                            <Select
                                {...influencerSelectProps}
                                value={selectedInfluencer}
                                onChange={(opt: ValueType<MicrowaveInfluencerOption, false>, action) => {
                                    influencerSelectProps.onChange?.(opt, action);
                                    resetEmailPreview();
                                }}
                                isDisabled={influencerIds.length === 0}
                                placeholder="Select influencer..."
                                isMulti={false}
                            />

                            <input
                                type="email"
                                value={currentEmailValue}
                                onChange={(e) => setSelectedOverride(e.target.value)}
                                className={inputStyles.input}
                            />
                        </div>

                        <EmailContent
                            isLoading={emailPreviewStatus === 'idle' || emailPreviewStatus === 'loading'}
                            error={emailPreviewError ? [emailPreviewError] : null}
                            preview={emailPreviewData}
                        />
                    </FullScreenModal.Content>
                    <FullScreenModal.Footer>
                        <Button
                            appearance="primary"
                            onClick={handleSend}
                            disabled={sendStatus === 'loading' || !selectedTemplate?.value}
                            isLoading={sendStatus === 'loading'}
                        >
                            Send
                        </Button>
                    </FullScreenModal.Footer>
                </>
            )}
        </FullScreenModal>
    );
};

export default InitialEmailPreviewModal;
