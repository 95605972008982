import { microwave } from '@round/api';
import { useAbortableEffect, useDataState } from '@round/utils';
import { useCallback } from 'react';

type Config = {
    params: microwave.EmailPreviewPostData;
    isDisabled?: boolean;
};

export function useEmailPreview({ params, isDisabled }: Config) {
    const [state, setState] = useDataState<microwave.EmailPreview>({
        status: 'idle',
        data: null,
        error: null,
    });

    const fetchData = useCallback(
        async (...params: Parameters<typeof microwave.postEmailPreview>) => {
            const errorMessage = 'Could not get email preview';
            setState('loading');
            try {
                const response = await microwave.postEmailPreview(...params);
                if (response.status === 200) {
                    setState('success', { data: response.data });
                    return;
                }

                setState('error', { error: errorMessage });
            } catch {
                setState('error', { error: errorMessage });
            }
        },
        [setState]
    );

    const isInitialized = state.status === 'success' || state.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (isInitialized || isDisabled) {
                return;
            }

            fetchData(
                {
                    campaign_id: params.campaign_id,
                    email_template_id: params.email_template_id,
                    influencer_id: params.influencer_id,
                },
                signal
            );
        },
        [fetchData, isInitialized, isDisabled, params.campaign_id, params.email_template_id, params.influencer_id]
    );

    const reset = () => setState('idle', { data: null });

    return { ...state, reset };
}
