import Modal, { ModalContent, ModalFooter, ModalProps, ModalTitle } from 'ui/General/Modal/Modal';
import { Button } from '@round/ui-kit';
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import FormField from 'ui/DataEntry/FormField/FormField';
import Label from 'ui/DataEntry/Label/Label';
import styles from './AddInfluencerPostGroupModal.module.css';
import useCreatorPlanTiktokAudios from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import { isNumber } from 'utility/utility';
import { findOrCreateTiktokAudio } from 'Modules/TikTok/TikTok.services';
import { findOrCreateInstagramAudio } from 'Modules/Instagram/Instagram.helpers';

type InfluencerPostGroupPlatformWithAudio = 'instagram' | 'tiktok';
export const isInfluencerPostGroupPlatformWithAudio = (value: any): value is InfluencerPostGroupPlatformWithAudio => {
    const platforms: InfluencerPostGroupPlatformWithAudio[] = ['instagram', 'tiktok'];
    return typeof value === 'string' && platforms.includes(value as InfluencerPostGroupPlatformWithAudio);
};

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
    platform: InfluencerPostGroupPlatformWithAudio;
};

type FormValues = {
    identifier: string;
};

const validationSchema = yup.object({
    identifier: yup.string().required('Audio is required'),
});

const AddInfluencerPostGroupModal = ({ isOpen, onClose, platform }: Props) => {
    const { createInstagramInfluencerPostGroup } = useNonNullContext(InstagramCreatorsContext);
    const { createTiktokInfluencerPostGroup } = useNonNullContext(TiktokCreatorsContext);
    const {
        influencerPlanAudios: tiktokInfluencerPlanAudios,
        resetInfluencerPlanAudios: resetTiktokInfluencerPlanAudios,
    } = useCreatorPlanTiktokAudios({ isImperative: true });

    const formik = useFormik<FormValues>({
        initialValues: { identifier: '' },
        onSubmit: async (values, { setFieldError, resetForm }) => {
            try {
                const findOrCreateAudio =
                    platform === 'instagram' ? findOrCreateInstagramAudio : findOrCreateTiktokAudio;
                const createGroup =
                    platform === 'instagram' ? createInstagramInfluencerPostGroup : createTiktokInfluencerPostGroup;

                const audioResult = await findOrCreateAudio(values.identifier);
                if (!audioResult.success) {
                    setFieldError('identifier', audioResult.error);
                    return;
                }

                const response = await createGroup({ audio_id: audioResult.data.id });
                if (!response) {
                    return;
                }

                resetForm();
                onClose?.();

                const existingTiktokAudioIds = tiktokInfluencerPlanAudios.map((a) => a.audio_id).filter(isNumber);
                const addedTiktokAudioId = response.data.audio_id;
                if (
                    platform === 'tiktok' &&
                    addedTiktokAudioId &&
                    !existingTiktokAudioIds.includes(addedTiktokAudioId)
                ) {
                    resetTiktokInfluencerPlanAudios();
                }
            } catch (e) {
                const message = e instanceof Error ? e.message : 'Could not create group';
                setFieldError('identifier', message);
            }
        },
        validationSchema,
    });

    const handleClose = () => {
        onClose?.();
        formik.resetForm();
    };

    return (
        <Modal closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <ModalTitle>Add Group</ModalTitle>

            <ModalContent>
                <FormikProvider value={formik}>
                    <Form>
                        <FormField>
                            <Label htmlFor="identifier">Audio</Label>
                            <Field
                                id="identifier"
                                name="identifier"
                                type="text"
                                placeholder="Enter audio url or identifier"
                            />

                            <ErrorMessage name="identifier">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>
                    </Form>
                </FormikProvider>
            </ModalContent>

            <ModalFooter align="right">
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    type="filled"
                    color="black"
                    loading={formik.isSubmitting}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={formik.submitForm}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddInfluencerPostGroupModal;
