import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { formatDecimalToPercentageString } from '@round/utils';
import { HeaderContext } from '@tanstack/react-table';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = HeaderContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableEngagementRateFooterCell = <T extends Row>({ table }: Context<T>) => {
    const { isLoading, areCampaignStatsLoading, campaignStats } = getTableMeta(table);
    if (isLoading || areCampaignStatsLoading) {
        return <Skeleton />;
    }

    if (typeof campaignStats?.engagement_rate !== 'number') {
        return '-';
    }

    return formatDecimalToPercentageString(campaignStats.engagement_rate);
};

export default PostsTableEngagementRateFooterCell;
