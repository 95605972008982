import { FieldHookConfig, useField } from 'formik';
import styles from './FormField.module.css';
import cn from 'classnames';
import TextArea, { TextAreaProps } from 'ui/TextArea/TextArea';

type Props = FieldHookConfig<string> & {
    placeholder?: string;
    className?: string;
    label?: string;
    inputClassName?: string;
} & Pick<TextAreaProps, 'autogrow'>;

const TextAreaField = ({
    label,
    className,
    placeholder,
    disabled,
    type,
    autogrow,
    inputClassName,
    ...props
}: Props) => {
    const [field, meta] = useField(props);

    const hasError = meta.error;

    return (
        <div className={cn(styles.field, className, { [styles.disabled]: disabled })}>
            <label className={styles.label} htmlFor={props.name}>
                {label}
            </label>
            <TextArea
                autogrow={autogrow}
                id={props.name}
                disabled={disabled}
                placeholder={placeholder}
                className={cn(styles.input, inputClassName, { [styles.error]: hasError })}
                {...field}
            />
            {hasError ? <span className={styles.errorMessage}>{meta.error}</span> : null}
        </div>
    );
};

export default TextAreaField;
