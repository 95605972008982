import { getInstagramAudios, InstagramAudio, postInstagramAudio } from '@round/api';

type FindOrCreateInstagramAudioResult = { success: true; data: InstagramAudio } | { success: false; error: string };

export async function findOrCreateInstagramAudio(identifier: string): Promise<FindOrCreateInstagramAudioResult> {
    const findResponse = await getInstagramAudios({ identifier_search: identifier });
    if (findResponse.status !== 200) {
        return { success: false, error: 'Error occurred on audio search' };
    }

    let audio: InstagramAudio | null = findResponse.data.results[0] ?? null;
    if (audio) {
        return { success: true, data: audio };
    }

    const postResponse = await postInstagramAudio({ identifier });
    if (postResponse.status === 201) {
        return { success: true, data: postResponse.data };
    }

    const message = Array.isArray(postResponse.data.identifier)
        ? postResponse.data.identifier.toString()
        : postResponse.data.identifier ?? 'Could not create audio';

    return { success: false, error: message };
}
