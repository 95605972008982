import { InfluencerPostGroup, YoutubeInfluencerPostGroup } from '@round/api';
import { useState } from 'react';
import Button from 'ui/Buttons/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import styles from './ConfirmDeleteGroupModal.module.css';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    group: InfluencerPostGroup | YoutubeInfluencerPostGroup | null;
    handleDeleteGroup: (id: number) => Promise<void>;
};

const ConfirmDeleteGroupModal = ({ isOpen, onClose, group, handleDeleteGroup }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        if (!group) {
            return;
        }

        try {
            setIsLoading(true);
            await handleDeleteGroup(group?.id);
            onClose();
        } catch {
            //no-op
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal closeOnOverlayClick className={styles.container} isOpen={isOpen} onClose={onClose}>
            <ModalTitle>Are you sure?</ModalTitle>
            <ModalContent>
                You are going to delete {group?.name} group. <br />
                This will also remove all items in the group
            </ModalContent>
            <ModalFooter align="center">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="filled" color="black" loading={isLoading} onClick={handleConfirm}>
                    Delete group
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDeleteGroupModal;
