import { useEffect, useMemo, useState } from 'react';
import { StringSearchParams, UrlData, useSessionUrlState } from '@round/utils';
import useInstagramContacted from './useInstagramContacted';
import InstagramContactedTable, { InstagramContactedTableRow } from './InstagramContactedTable/InstagramContactedTable';
import { mapStringListToArray, showNotification } from 'helpers';
import { RowSelectionState } from '@tanstack/react-table';
import uniq from 'lodash/uniq';
import { microwave } from '@round/api';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import InstagramContactedFilters from './InstagramContactedFilters/InstagramContactedFilters';
import styles from './InstagramContacted.module.css';
import Button from 'ui/Button/Button';
import { Popover } from '@round/ui-kit';
import Card from 'ui/Card/Card';
import Calendar from 'ui/DataEntry/Calendar/Calendar';
import moment from 'moment';
import ChaseEmailPreviewModal from '../../../EmailPreview/ChaseEmailPreviewModal';
import EmailPreviewButton from '../../../components/EmailPreviewButton/EmailPreviewButton';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';

type Props = {
    campaignId: number;
};

type UrlState = {
    page: number;
    pageSize: number;
    selected?: string[];
    ordering?: microwave.GetInvitesParams['ordering'];
    chaseEmailCount?: number;
    showOnlySelected?: boolean;
};

const initialState: UrlState = {
    page: 1,
    pageSize: 10,
};

const parseUrlState = ({
    searchParams: { page, pageSize, selected, ordering, chaseEmailCount, showOnlySelected },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialState.page,
    pageSize: pageSize ? Number(pageSize) : initialState.pageSize,
    selected: mapStringListToArray(selected ?? ''),
    ordering: ordering as UrlState['ordering'],
    chaseEmailCount: chaseEmailCount ? Number(chaseEmailCount) : undefined,
    showOnlySelected: showOnlySelected === 'true',
});

const InstagramContacted = ({ campaignId }: Props) => {
    const { url, mergeSearchParams, persistSession } = useSessionUrlState<UrlState>(
        'mw-ig-campaign-contacted-params',
        (prevUrlData, sessionData, helpers) => {
            const fallbackState = Object.fromEntries(
                Object.entries({ ...initialState, ...sessionData?.searchParams }).filter(
                    ([key]) => !Object.hasOwn(prevUrlData.searchParams, key)
                )
            );

            return helpers.merge(prevUrlData, { searchParams: fallbackState });
        }
    );

    const { page, pageSize, selected, ordering, chaseEmailCount, showOnlySelected } = parseUrlState(url);
    const {
        data,
        status,
        error,
        creatorsData,
        audiosData,
        instagramUserImagesData,
        getIsAccountDataLoading,
        reset,
        update: updateInvite,
    } = useInstagramContacted({
        params: {
            page,
            page_size: pageSize,
            campaign_id: campaignId,
            ordering,
            ...(showOnlySelected ? { id: selected?.toString() } : { chase_email_count: chaseEmailCount }),
        },
    });

    useEffect(() => {
        return () => {
            persistSession({ searchParams: url.searchParams });
        };
    }, [persistSession, url.searchParams]);

    const rows = useMemo(() => {
        return (data?.results ?? []).map<InstagramContactedTableRow>((invite) => {
            const creator = creatorsData[invite.influencer_id]?.data ?? null;
            return {
                ...invite,
                creator,
                audio: invite.instagram_audio_id ? audiosData[invite.instagram_audio_id]?.data ?? null : null,
                image: creator?.instagram_user_id
                    ? instagramUserImagesData[creator.instagram_user_id]?.data ?? null
                    : null,
            };
        });
    }, [audiosData, creatorsData, data?.results, instagramUserImagesData]);

    const [deadlineButtonRef, setDeadlineButtonRef] = useState<HTMLButtonElement | null>(null);
    const [deadlineSelectValue, setDeadlineSelectValue] = useState(moment().format('YYYY-MM-DD'));
    const [isDeadlineUpdateLoading, setIsDeadlineUpdateLoading] = useState(false);

    const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState(false);

    const selectedCount = selected?.length ?? 0;
    const isEmailPreviewDisabled = selectedCount === 0;

    return (
        <>
            <menu className={styles.menuContainer}>
                <div className={styles.actions}>
                    {selectedCount > 0 && (
                        <>
                            <Button
                                className={styles.actionButton}
                                appearance="ghost"
                                onClick={() => {
                                    if (showOnlySelected) {
                                        mergeSearchParams({
                                            page: 1,
                                            showOnlySelected: undefined,
                                            selected: undefined,
                                        });
                                        reset();
                                        return;
                                    }

                                    mergeSearchParams({ selected: undefined });
                                }}
                            >
                                Clear selection
                            </Button>

                            <Button ref={setDeadlineButtonRef} className={styles.actionButton} appearance="ghost">
                                Set deadline{selected?.length === 1 ? '' : 's'}
                            </Button>

                            <Popover
                                anchorElement={deadlineButtonRef}
                                showOn="click"
                                options={{ placement: 'bottom-start' }}
                            >
                                {(setShow) => (
                                    <Card className={styles.deadlineSelectPopover}>
                                        <Calendar
                                            value={deadlineSelectValue}
                                            onChange={setDeadlineSelectValue}
                                            minDate={new Date()}
                                        />

                                        <Button
                                            className={styles.setDeadlineSubmit}
                                            appearance="ghost"
                                            isLoading={isDeadlineUpdateLoading}
                                            onClick={async () => {
                                                if (!selected?.length) {
                                                    return;
                                                }

                                                try {
                                                    setIsDeadlineUpdateLoading(true);
                                                    const results = await Promise.allSettled(
                                                        selected.map((id) =>
                                                            updateInvite(id, { deadline: deadlineSelectValue })
                                                        )
                                                    );

                                                    const allUpdated = results.every(
                                                        (result) => result.status === 'fulfilled'
                                                    );
                                                    if (allUpdated) {
                                                        showNotification('all updated', 'info');
                                                        setShow(false);
                                                        setDeadlineSelectValue(moment().format('YYYY-MM-DD'));
                                                        return;
                                                    }

                                                    showNotification('Could not update some or all dates', 'error');
                                                    return;
                                                } catch {
                                                    showNotification('Could not save multiple deadlines', 'error');
                                                } finally {
                                                    setIsDeadlineUpdateLoading(false);
                                                }
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Card>
                                )}
                            </Popover>
                        </>
                    )}
                </div>

                <div className={styles.filtersContainer}>
                    <label className={styles.showOnlySelectedFilter}>
                        Show only selected&nbsp;
                        <Checkbox
                            value={showOnlySelected!}
                            onChange={(value) => {
                                mergeSearchParams({ showOnlySelected: value || undefined, page: 1 });
                                reset();
                            }}
                        />
                    </label>

                    <InstagramContactedFilters
                        value={{ chase_email_count: chaseEmailCount }}
                        onChange={(values) => {
                            mergeSearchParams({ chaseEmailCount: values.chase_email_count, page: 1 });
                            reset();
                        }}
                    />

                    <EmailPreviewButton
                        onClick={() => setIsEmailPreviewModalOpen(true)}
                        disabled={isEmailPreviewDisabled}
                        hint={isEmailPreviewDisabled ? 'Select users to preview email' : undefined}
                    />
                </div>
            </menu>

            <InstagramContactedTable
                data={rows}
                page={page}
                setPage={(page) => mergeSearchParams({ page })}
                pageSize={pageSize}
                setPageSize={(pageSize) => mergeSearchParams({ pageSize })}
                count={data?.count ?? 0}
                noDataLabel={status === 'error' ? error : 'No data'}
                meta={{ isLoading: status === 'loading' || status === 'idle', getIsAccountDataLoading }}
                rowSelection={
                    selected?.reduce<RowSelectionState>((acc, rowId) => {
                        acc[rowId] = true;
                        return acc;
                    }, {}) ?? {}
                }
                onRowSelectionChange={(selection) => {
                    const incomingIds = rows.filter((row) => selection[row.id]).map((r) => r.id);
                    mergeSearchParams({
                        selected: uniq(incomingIds.concat(selected?.filter((id) => selection[id]) ?? [])),
                    });
                }}
                sorting={mapOrderingToTableSorting(ordering ? [ordering] : [])}
                onSortingChange={(sorting) => mergeSearchParams({ ordering: mapTableSortingToOrdering(sorting) })}
            />

            <ChaseEmailPreviewModal
                isOpen={isEmailPreviewModalOpen}
                onClose={() => setIsEmailPreviewModalOpen(false)}
            />
        </>
    );
};

export default InstagramContacted;
