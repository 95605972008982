import { call } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';
import { EmailPreview } from './emailPreview.types';

export type EmailPreviewPostData = {
    email_template_id: number;
    campaign_id: number;
    influencer_id: number;
};

type EmailPreviewPostResponse =
    | ApiResponse<EmailPreview, 200>
    | ApiResponse<string | string[], 400>
    | ApiResponse<ApiResponseError<EmailPreviewPostData>, 400>;

export async function postEmailPreview(
    data: EmailPreviewPostData,
    signal?: AbortSignal
): Promise<EmailPreviewPostResponse> {
    const response = await call('/api/microwave/email-preview/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not send email preview');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
